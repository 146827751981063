import React from "react";
import CountUp from "react-countup";
import icon from "../../media/images/Full-Body-Hair-Removal-For-Male-Salon.png";
import icon1 from "../../media/images/04.png";
import icon2 from "../../media/images/05.png";
import icon3 from "../../media/images/06.png";
import img from "../../media/images/Waxing-Services-At-Home.jpg";
import img1 from "../../media/images/Glazma-Mens-salon.jpg";
import img01 from "../../media/images/Glazma-meeting-refresh.jpg";
import img02 from "../../media/images/Glazma-event-party-grooming.jpg";
import img03 from "../../media/images/Glazma-wedding-grooming.jpg";
import img04 from "../../media/images/Glazma-honeymoon-grooming.jpg";
import { Link } from "react-router-dom";

const AboutPageComponent = () => {
  return (
    <div>
      <div className="about-head">
        <div>
          <h1 className="about-head-heading-1">
            GLAZMA MEN'S BEAUTY SALON AT HOME
          </h1>
          {/* <div className="about-head-heading-2">(A VENTURE BY BHUMU GROUP)</div> */}
          <div className=" about-head-icon ">
            <img title="Affordable Waxing Near Me" src={icon} alt="Affordable Waxing Near Me" />
          </div>
          <div className="about-head-col">
            <div className="about-head-col-1">
              <img title="Best Way To Get Rid Of Hair Down There" src={img1} alt="Best Way To Get Rid Of Hair Down There" />
            </div>
            <div className="about-head-col-2">
              <div className="about-head-col-2-heading">ABOUT US</div>
              <div className="about-head-text">
                Hey men’s now no need to be worry to manage time for body
                waxing, grooming and personal care (body wax, bikini wax, pubic
                hair shave, and under cloth grooming) services. We offer
                doorstep services for men’s grooming, our body wax professionals
                and beauty experts always available to welcome you at your
                preferred location. Currently, this facility is available in
                Noida, Noida Extension, Greater Noida, Ghaziabad, Indirapuram,
                Rajnagar Extension, Faridabad, Gurgaon, DELHI -NCR also at your
                home, office, or hotel you stay in the city.
              </div>
            </div>
          </div>

          <div className="about-head-text">
            Glazma - manscaping services salon near me gives men the opportunity
            to look and feel they're very best. We deliver one stop solution for
            men’s personal care like -body wax, body scrub, body polishing,
            manicure, pedicure, facial, pubic hair shave your body hair from
            your full arm & under arms wax, below the belt shave, full body hair
            trim, body wax, lighten the dark intimate area and pre wedding groom
            services etc.
          </div>
          <h2 className="about-head-heading-3">
            Glazma – Manscaping Services at Home
          </h2>
          <div className="about-head-text">
            Glazma is highly skilled and trained to provide personalized
            services tailored to each client's needs, ensuring they leave
            feeling confident and refreshed.
          </div>
          <br />
          <div className="about-head-text">
            Glazma men’s salon gives men the opportunity to look and feel their
            very best we deliver one stop solution for men's personal care like
            a Full body wax, Pubic hair shave, Full body scrub, Full body
            polishing, Pre-wedding groom services, Full body detan, Full body
            haldi Chandan pack, Facial and detan, and Body massage etc.
            <br />
            <br />
            We offer world class men's grooming service in a luxurious setting.
            Our services are a mixture of everything – having good hygiene,
            being true to yourself. Grooming your body hair is personal, to a
            point. Whether you want to completely get rid of hair or just keep
            it trimmed depends on your preference, but for some area you may not
            able to shave and trim like your pubic, butt, back, under arms and
            below the belt. But now, this is not a challenge, we make it easy we
            come at your place to design, wax, shave or trim your body hairs.
          </div>
        </div>
      </div>

      <div className="about-beliefs">
        <div>
          <div className="about-beliefs-heading-1">OUR BELIEFS</div>
          <div className="about-beliefs-icon">
            <img title="Male Pubic Hair Trimming Service Near Me" src={icon} alt="Male Pubic Hair Trimming Service Near Me" />
          </div>
          <div className="about-beliefs-text">
            "Gentlemen is A man who smells good, who looks good, who keeps their
            body parts clean good" and glazma is in process to designs a normal
            man to a Gentlemen.
          </div>
          <div className="about-beliefs-content">
            <div>
              <div className="about-beliefs-content-col">
                <div className="about-beliefs-content-col-icon">
                  <i className="fa-solid fa-globe"></i>
                </div>
                <div className="about-beliefs-content-col-heading">
                  Our Mission
                </div>
              </div>
              <div className="about-beliefs-content-col">
                <div className="about-beliefs-content-col-text">
                  We aim to create an brand where gentlemen of all ages can
                  receive the highest level of grooming and personal care
                  service.
                </div>
              </div>
            </div>

            <div>
              <div className="about-beliefs-content-col">
                <div className="about-beliefs-content-col-icon">
                  <i className="fa-solid fa-signal"></i>
                </div>
                <div className="about-beliefs-content-col-heading">
                  Our Vision
                </div>
              </div>
              <div className="about-beliefs-content-col">
                <div className="about-beliefs-content-col-text">
                  To design a male-oriented highend brand for men's who demanded
                  more than the corner barbershop or quick grooming service.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-purpose">
        <div>
          <div className="about-purpose-text">
            Our Glazma men's grooming experts are qualified, trained and
            certified also. We are taking care of men’s Day to day care at home
            and also ensuring their corporate needs, party and pre wedding
            grooming and makeup services. We are also giving our services to
            fashion and photographic industry with high profile hair styling,
            body waxing and makeup services. In our industry, we are known for
            our focus on training, customer focus, service consistency and
            quality standards.
          </div>
          <div className="about-purpose-heading">PURPOSE</div>
          <div className="about-purpose-icon">
            <img title="Pubic Waxing" src={icon} alt="Pubic Waxing" />
          </div>
          <div className="about-purpose-text">
            We exist to empower gentleman to look and feel fantastic in order to
            take on the challenge of life and achieve their aspirations. Our
            dedicated team of male grooming professionals have a combined
            experience of over 10+ yrs. and we are passionate about developing
            partnerships with our clientele through a deep understanding of what
            they are aiming to achieve. Every one of our clients receives a
            grooming experience that is bespoke to their individual needs.
          </div>

          <div className="about-purpose-heading">COMMITMENT TO EXCELLENCE</div>
          <div className="about-purpose-icon">
            <img title="Pubic Hair Waxing Near Me" src={icon} alt="Pubic Hair Waxing Near Me" />
          </div>
          <div className="about-purpose-img">
            <img title="Waxing Nearby" src={img} alt="about img" />
          </div>
          <div className="about-purpose-text">
            The highly trained & experienced beauty and waxing expert at your
            doorstep. The Glazma professions are experts in men’s body wax, body
            scrub, body polishing, manicure, pedicure, facial, pubic hair wax,
            shave your body hair from your full arm wax & under arms wax, below
            the belt wax or shave, nail cutting, ear & nose hair clean, full
            body massage and pre wedding grooming services also. Glazma maintain
            the quality standards and has a robust and comprehensive approach to
            delivering services and staff to ensure that every men’s grooming
            experience and waxing services that we develop is of the highest
            quality.
          </div>
        </div>
      </div>

      <div className="about-section-2">
        <div>
          <div className="about-section-2-heading">
            WE ALWAYS READY TO GIVE OUR SERVICES TO MEN "WHO WANT TO BECOME A
            GENTLEMAN"
          </div>
          <div className="about-section-2-text">
            It is not uncommon for people to offer salon services, including
            men's salon services, in a home setting. Some men may prefer the
            convenience and privacy of getting salon services at home, rather
            than going to a commercial salon or spa. If you are considering
            getting men's beauty salon services at home, it is important to
            research the person offering the services and ensure that they are
            trained and experienced in the services they provide. Make sure to
            ask for references or reviews from previous clients, and ask about
            the products and techniques being used.
          </div>
        </div>
      </div>

      <div className="about-section-3">
        <div>
          <div className="about-section-3-heading-1">
            GLAZMA - MEN'S BODY WAXING SALON WORKING PROCESS
          </div>
          <div className="about-section-3-heading-2">
            PREVENT MANSCAPING ACCIDENTS NOW
          </div>
          <div className="about-section-3-icon">
            <img title="Bikini Area Hair Removal" src={icon} alt="icon" />
          </div>
          <div className="about-section-3-text">
            Glazma offers a range of men's beauty services designed to enhance
            and maintain their appearance. Glazma's experienced staff ensures
            that each client receives personalized attention and quality service
            at your preferred location.
          </div>
          <div className="about-section-3-col">
            <div className="about-section-3-col-point">
              <div className="about-section-3-col-point-icon">
                <img title="Manzilian Near Me" src={icon1} alt="icon" />
              </div>
              <div>
                <div className="about-section-3-col-point-heading">BOOK</div>
                <div className="about-section-3-col-point-text">
                  Select services with time and date you'd like us to reach your
                  doorstep.
                </div>
              </div>
            </div>

            <div className="about-section-3-col-point">
              <div className="about-section-3-col-point-icon">
                <img title="Wax Appointment Near Me" src={icon2} alt="icon" />
              </div>
              <div>
                <div className="about-section-3-col-point-heading">GROOM</div>
                <div className="about-section-3-col-point-text">
                  Our Grooming expert reach at your doorstep for professional
                  service on your time.
                </div>
              </div>
            </div>

            <div className="about-section-3-col-point">
              <div className="about-section-3-col-point-icon">
                <img title="Waxing Nearby" src={icon3} alt="icon" />
              </div>
              <div>
                <div className="about-section-3-col-point-heading">
                  PAY & RELAX
                </div>
                <div className="about-section-3-col-point-text">
                  After service satisfaction pay as per convenient we have
                  multiple payment options.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-section-4">
        <div>
          <div className="about-section-4-heading">CORPORATE & SPECIAL CARE BEAUTY SALON SERVICES</div>
          <div className="about-head-icon"><img title="Pubic Waxing" src={icon} alt="Pubic Waxing" /></div>
          <div className="about-section-4-col">
            
            <div className="about-section-4-col-point">
              <div className="about-section-4-col-point-image"><img title="Wax Locations Near Me" src={img01} alt="Wax Locations Near Me" /></div>
              <div className="about-section-4-col-point-content">
              <div className="about-section-4-col-point-heading">Meeting Refresh</div>
              <div className="about-section-4-col-point-text">Refresh salon look makes you smarter at meetings. If you are traveling to another city for a corporate meeting or any function, we will be there to give you a fresh look. ........... </div>
              <Link title="Male Full Body Wax" target="_blank" to="/meeting-refresh.php" >Read more</Link>
              </div>

            </div>
            
            
            <div className="about-section-4-col-point">
              <div className="about-section-4-col-point-image"><img title="Pubic Waxing Near Me" src={img02} alt="Pubic Waxing Near Me" /></div>
              <div className="about-section-4-col-point-content">
              <div className="about-section-4-col-point-heading">Event/Party Grooming</div>
              <div className="about-section-4-col-point-text">Party season is fast approaching, and although when it comes to party preparation we tend to only consider women,..........</div>
              <Link title="Pubic Waxing Near Me" target="_blank" to="/event-party.php" >Read more</Link>
              </div>

            </div>
            
            
            <div className="about-section-4-col-point">
              <div className="about-section-4-col-point-image"><img title="Butt Wax Near Me" src={img03} alt="Butt Wax Near Me" /></div>
              <div className="about-section-4-col-point-content">
              <div className="about-section-4-col-point-heading">Wedding Grooming</div>
              <div className="about-section-4-col-point-text">On your wedding day, you'll want to look like the best version of yourself? The bride always steals the attarction at a wedding But, now days its time for the groom to claim an equal space...........  </div>
              <Link title="Pubic Hair Waxing Near Me" target="_blank" to="/wedding-groomings.php" >Read more</Link>
              </div>

            </div>
            
            
            <div className="about-section-4-col-point">
              <div className="about-section-4-col-point-image"><img title="Good Waxing Places Near Me" src={img04} alt="Good Waxing Places Near Me" /></div>
              <div className="about-section-4-col-point-content">
              <div className="about-section-4-col-point-heading">Honeymoon Special Grooming</div>
              <div className="about-section-4-col-point-text">Manscape Before Honeymoon is a sign of gentlemen so be a gentlemen 85% women think honeymoon sex would have been so much better with a hair-free husband.  .... </div>
              <Link title="Pubic Waxing" target="_blank" to="/honey-moon.php" >Read more</Link>
              </div>

            </div>
            

          </div>
        </div>
      </div>

      <div className="about-section-3">
<div>
      <div className="about-section-3-heading-1">MEN'S GROOMING SERVICES AT DOORSTEP</div>
      <div className="about-section-3-heading-2">MAKEUP ARTIST & MEN'S PARLOUR AT HOME SERVICE</div>
      <div className="about-section-3-icon">
            <img title="Male Full Body Wax" src={icon} alt="icon" />
          </div>
      <div className="about-section-3-text">We provide personalized full body wax, body scrub, body polishing, manicure, pedicure, facial, pubic hair shave your body hair from your head to your toes and all in between. We offer the best men's full body grooming and personal care (body wax, body scrub, body polishing, manicure, pedicure, facial, pubic hair shave your body hair from your full arm & under arms wax, below the belt shave, full body hair trim body wax, lighten the drak intemate area and pre wedding groom services etc.) of a fine cut and shave and make you look good and feel good.
<br />
<br />
Finally no need to afraid of cutting yourself Glazma beauty and waxing professional experts come at your home and groom, shave, wax and trim as you required in your body from head to your toes. It's a better way to get your body wax, body scrub, body polishing, manicure, pedicure, facial, pubic hair shave your body hair from your body parts hair and complete men's grooming and makeup for any special occassions. Say goodbye to cold calls, wait times, and travel to salon or barber appointments.</div>

      <div className="about-section-5-increment">
        
          <div className="why-us-increment-col">
            <div className="why-us-increment-col-heading">
              <CountUp end={85} duration="5" enableScrollSpy /> %
            </div>
            <div className="why-us-increment-col-text">
              OF WOMEN THINK BAD GROOMING IS A MAJOR TURN OFF IN MENS
              PERSONALITY.
            </div>
          </div>

          <div className="why-us-increment-col">
            <div className="why-us-increment-col-heading">
              <CountUp end={80} duration="5" enableScrollSpy /> %
            </div>
            <div className="why-us-increment-col-text">
              OF WOMEN THINK, MEN SHOULD MANSCAPE UNDERARMS AND BELOW THE BELT
            </div>
          </div>

          <div className="why-us-increment-col">
            <div className="why-us-increment-col-heading">
              <CountUp end={89} duration="5" enableScrollSpy /> %
            </div>
            <div className="why-us-increment-col-text">
              OF MEN THINK GROOMING IS ESSENTIAL TO THEIR PROFESSIONAL SUCCESS
            </div>
          </div>
        
      </div>
      </div>
      </div>
      
    </div>
  );
};

export default AboutPageComponent;
