import React from "react";
import AdminContactUsComponent from "../../components/admin/AdminContactUsComponent";

const AdminContactUs = () => {
  return (
    <div>
      <AdminContactUsComponent />
    </div>
  );
};

export default AdminContactUs;
