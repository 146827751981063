import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import BlogBreadCrumbComponent from "../../components/user/BlogBreadCrumbComponent";
import img1 from "../../media/images/blog-img/grooming-blog.jpg";
import RecentBlogComponent from "../../components/blog/RecentBlogComponent";
import BlogGlazmaComponent from "../../components/blog/BlogGlazmaComponent";
import { Helmet } from "react-helmet";


const GroomingTips = () => {
  return (
    <div>


    <Helmet>
            <title>Look Good. Smell Good. Feel Good.</title>
            <meta
              name="description"
              content="
              Most of the men think grooming and skin care is not much important They are men, they do not have to do it. Wrong, you can be best dressed and money on pocket 
             "
            />
          </Helmet>
      
      
    <Navbar />
    <BlogBreadCrumbComponent name=" Grooming Tips Every Men's Should Know" />

    <div className="blog-page">
      <div>
        <div className="single-blog">
          <h1 className="single-blog-heading">
          Look Good. Smell Good. Feel Good.
          </h1>
          <div className="single-blog-text">
          No one born Gentlemen, Some men always keep them up to the trend and fashion Hair always on point, Skin always glow and clear They just knw grooming tips that you don't.
          </div>
          <div className="single-blog-img">
            <img title="blog-img"  src={img1} alt="blog-img" />
          </div>
          <div className="single-blog-text">
          Most of the men think grooming and skin care is not much important They are men, they do not have to do it. Wrong, you can be best dressed and money on pocket but if you smell bad, hands sweaty and oily hairs not stylish you are not able to creat a good impression on anyone.
          </div>

          <div className="single-blog-heading">
          Good grooming is an essential part of a gentlemen.
          </div>

          <div className="single-blog-text">
          Don’t worry though because we got you covered as always. How?

<br />
<br />
Cut Your Nails always in 3-4 days
<br />
Shave/Trim properly on beard
<br />
Wear Deodorant/Antiperspirant
<br />
Trim Nose/Ear Hair
<br />
Take Care Of Bad Breath
<br />
Have Warm Showers, Not Hot
<br />
Use Sunscreen During Summer
<br />
<br />
Most of these tasks take less than a few minutes to take care off, while others you can do simultaneously. There is no excuse for you not to have clean teeth, smell good and trimming your beard or paying that little more attention during your haircut.
          </div>


          {/* ------------------------------------------------------------------------------------------- */}
        </div>

        <div className="blog-page-col-2">
          <BlogGlazmaComponent />
          <RecentBlogComponent />
        </div>
      </div>
    </div>

    <LocationComponent />
    <FooterComponent />
  </div>
  )
}

export default GroomingTips