import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import BlogBreadCrumbComponent from "../../components/user/BlogBreadCrumbComponent";
import img1 from "../../media/images/blog-img/faces-blog.jpg";
import img2 from "../../media/images/blog-img/beard-care1.jpg";
import RecentBlogComponent from "../../components/blog/RecentBlogComponent";
import BlogGlazmaComponent from "../../components/blog/BlogGlazmaComponent";
import { Helmet } from "react-helmet";


const FaceAndBeardGroomingTips = () => {
  return (
    <div>


    <Helmet>
            <title>Glazma - Face and Beard Grooming Tips</title>
            <meta
              name="description"
              content="
              Massage the skin with iced yoghurt and sprinkle some sugar along with it. after 1 minute take orange halves and scrub gently until the granules melt. 
             "
            />
          </Helmet>
      
      
    <Navbar />
    <BlogBreadCrumbComponent name=" Face And Beard Grooming Tips" />

    <div className="blog-page">
      <div>
        <div className="single-blog">
          <h1 className="single-blog-heading">
          Face and Beard Grooming Tips
          </h1>
          
          <div className="single-blog-img">
            <img title="blog-img"  src={img1} alt="blog-img" />
          </div>
          <div className="single-blog-heading">Skin Care for Oily and Hard Skin</div>
          <div className="single-blog-text">
          Massage the skin with iced yoghurt and sprinkle some sugar along with it. after 1 minute take orange halves and scrub gently until the granules melt. Wash the face with iced water oily and hard skin become soft in 4-5 times repeation of this activity.
          </div>

          <div className="single-blog-heading">
          For Dull, Tired and Dry Skin
          </div>

          <div className="single-blog-text">
          Massage the skin with papaya. Then make a scrub by mixing oats and honey along with a little cold milk and scrub the skin. Wash off with ice cold milk and water and pat dry.
          </div>


          <div className="single-blog-heading">
          For Tired Eyes
          </div>

          <div className="single-blog-text">
          When we work long hours on laptop or office or outing our eyes seems tired mix some drops of rose water and few drops of honey in normal water and wash your eyes then same in other you will feel refreshed in minutes.
          </div>


          <div className="single-blog-heading">
          Beard Care
          </div>
          <div className="single-blog-img">
            <img title="blog-img"  src={img2} alt="blog-img" />
          </div>


          <div className="single-blog-heading">
          Nobody likes unexpected floss in their teeth
          </div>

          <div className="single-blog-text">          
          There are plenty of great beard-specific shampoos out there that use special botanicals to help soften the hair and keep it from getting dried out. Just make sure that whatever you, use you like the smell of, since it's right there by your nose.
          </div>


          <div className="single-blog-heading">
          Use Beard Oil
          </div>

          <div className="single-blog-text">
          Use any good oil in your beard to balance its hydration naturally.a good beard oil will soften it even further, plus has the added advantage of warding off the dreaded beard dandruff.
          
          </div>


          <div className="single-blog-heading">
          Brush your Beard
          </div>

          <div className="single-blog-text">
          Keep your beard from getting tangled and unruly by brushing it everyday with a beard brush. It'll also help spread the beard oil you're using (as well as your natural face oils) to keep your beard as healthy as possible.

<br />
<br />
Note: The opinions expressed within this article are the opinions of our research team and experts personal opinions. Glazma is not responsible for the accuracy, completeness, suitability, or validity of any information on this article. All information is provided on an as-is basis.
          </div>





          {/* ------------------------------------------------------------------------------------------- */}
        </div>

        <div className="blog-page-col-2">
          <BlogGlazmaComponent />
          <RecentBlogComponent />
        </div>
      </div>
    </div>

    <LocationComponent />
    <FooterComponent />
  </div>
  )
}

export default FaceAndBeardGroomingTips