import React, { useState } from "react";
import LoadingComponent from "../user/LoadingComponent";
import { Link, Navigate, useNavigate } from "react-router-dom";

import logo from "../../media/images/glazma-logo.png";

const AdminNav = () => {


  const [loading, setLoading] = useState(false);

  
  const navigate = useNavigate()
  const handleLogout = () => {
    setLoading(true);

    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("userEmail");
    alert("Admin logout successful");
    navigate("/admin");
    window.location.reload();
    setLoading(false);

  };
  
  if(!localStorage.getItem("userAuthToken")){
    return <Navigate to="/admin"/>
  }
    
  return (
    <div>
      <div>
        {loading ? (
          <div className="data-loading">
            <LoadingComponent />
          </div>
        ) : (
          ""
        )}

        <div className="admin-nav">

          <Link to="/admin/home" className="nav-logo admin-nav-logo">
            <img src={logo} alt="" />
          </Link>

          <div className="admin-nav-btn" onClick={handleLogout}>
            logout
          </div>

        </div>
      </div>
    </div>
  );
};

export default AdminNav;
