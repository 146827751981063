import React, { useState } from 'react'
import logo from '../../media/images/glazma-logo.png'
import { Navigate, useNavigate } from 'react-router-dom';
import LoadingComponent from '../../components/user/LoadingComponent';

const AdminLogin = () => {

    
  const [loading, setLoading] = useState(false);

    
    
  const navigate = useNavigate()


  
const [user, setUser] = useState({
  numberOrEmail: "",
  password: "",
});


const handleChange = (e) => {
  const value = e.target.value;
  setUser({
    ...user,
    [e.target.name]: value
  });
};


const handleSubmit = async (e) => {
  setLoading(true);

  e.preventDefault();

  try {
    const response = await fetch("https://api.shop.glazma.com/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
    "authorization": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE3MDkyNzE5NzMsImV4cCI6MTc0MDgwNzk3MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.kxlQwL40hSCaGzIV9WM9MJTjx8zK0eDhp-eB8Ennroo"
  },
      body: JSON.stringify(user),
    });

    if (response.ok) {
      
      const res_data = await response.json();
      localStorage.setItem("userAuthToken", res_data.token)
      navigate("/admin/home");
      alert("login sucessful");
    } else {
      // console.log("invalid credential...");
      const res_data = await response.json();
      // console.log("err res", response)
      // console.log("err res data", res_data)
      alert(res_data.message)
      console.log("Login Error : ", res_data.message)
    }

    // console.log("try res ", response);
  } catch (error) {
    console.log("catch err" , error);
    alert("server error")
  }
  setLoading(false);

};


if(localStorage.getItem("userAuthToken")){
  return <Navigate to="/admin/home"/>
}
  
    
  return (
    <div>

      
{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      
        <div className="admin-login">
        <div className="admin-login-content">
        <div className="admin-login-logo">
            <img src={logo} alt="" />
        </div>
        <div className="admin-login-heading-1">Welcome Back!</div>
        <div className="admin-login-heading-2">Admin LogIn Only!</div>
        <div className="admin-login-form">
            <form onSubmit={handleSubmit}>
        <div className="admin-login-input"><span><i className="fa-solid fa-user"></i></span><input required type="text" placeholder='email or mobile number' name="numberOrEmail" onChange={handleChange}  /></div>
        <div className="admin-login-input"><span><i className="fa-solid fa-lock"></i></span><input required placeholder='password'  type='password'name="password" onChange={handleChange} /></div>
        <button className="admin-login-button">Log in</button>

            </form>
        </div>

        </div>
            
        </div>
    </div>
  )
}

export default AdminLogin