import React from 'react'
import Navbar from '../../components/user/Navbar'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'
import PrivacyPolicyComponent from '../../components/user/PrivacyPolicyComponent'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'

const PrivacyPolicyPage = () => {
  return (
    <div>
        <Navbar/>
        <BreadCrumbComponent name="privacy policy" />
<PrivacyPolicyComponent/>
        <LocationComponent/>
        <FooterComponent/>
    </div>
  )
}

export default PrivacyPolicyPage