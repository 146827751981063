import React from 'react'
import Navbar from '../../components/user/Navbar'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'
import AboutPageComponent from '../../components/user/AboutPageComponent'
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <div>


<Helmet>
        <title>About Glazma : Mens Grooming and Waxing Service At Home.
        </title>
        <meta
          name="description"
          content="
          Men's grooming and waxing service at home in Noida, Delhi NCR. Our experts provide personalized services tailored to each client's need.
         "
        />
      </Helmet>
      
      
        <Navbar/>
        <BreadCrumbComponent name="about us"/>
        <AboutPageComponent/>


        <LocationComponent/>
        <FooterComponent/>
    </div>
  )
}

export default AboutPage