import React from "react";
import Slider from "react-slick";

const HeaderSliderComponent = () => {

  var settings = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
  };

  
  return (
    <div>
    <Slider {...settings}>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-1">
          <div className="header-slider-heading-1">
          MEN'S GROOMING IS 
          <br />
          NOW AVAILABLE DOORSTEP
          </div>
          <div className="header-slider-heading-2">
          Professional services for Perfect Look
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-2">
          <div className="header-slider-heading-1">
          SAY GOODBYE TO  
          <br />
          COLD CALLS AND WAIT TIMES
          </div>
          <div className="header-slider-heading-2">
          Our grooming expert come at your Doortsep
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-3">
          <div className="header-slider-heading-1">
          GO HAIRLESS
          <br />
          RELAX, REJUVENATE!
          </div>
          <div className="header-slider-heading-2">
          Book our Professional Waxing Therapists
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-1">
          <h1 className="header-slider-heading-1">
          MEN'S GROOMING IS 
          <br />
          NOW AVAILABLE DOORSTEP
          </h1>
          <div className="header-slider-heading-2">
          Professional services for Perfect Look
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-2">
          <div className="header-slider-heading-1">
          SAY GOODBYE TO  
          <br />
          COLD CALLS AND WAIT TIMES
          </div>
          <div className="header-slider-heading-2">
          Our grooming expert come at your Doortsep
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-3">
          <div className="header-slider-heading-1">
          GO HAIRLESS
          <br />
          RELAX, REJUVENATE!
          </div>
          <div className="header-slider-heading-2">
          Book our Professional Waxing Therapists
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-1">
          <div className="header-slider-heading-1">
          MEN'S GROOMING IS 
          <br />
          NOW AVAILABLE DOORSTEP
          </div>
          <div className="header-slider-heading-2">
          Professional services for Perfect Look
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-2">
          <div className="header-slider-heading-1">
          SAY GOODBYE TO  
          <br />
          COLD CALLS AND WAIT TIMES
          </div>
          <div className="header-slider-heading-2">
          Our grooming expert come at your Doortsep
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-3">
          <div className="header-slider-heading-1">
          GO HAIRLESS
          <br />
          RELAX, REJUVENATE!
          </div>
          <div className="header-slider-heading-2">
          Book our Professional Waxing Therapists
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-1">
          <div className="header-slider-heading-1">
          MEN'S GROOMING IS 
          <br />
          NOW AVAILABLE DOORSTEP
          </div>
          <div className="header-slider-heading-2">
          Professional services for Perfect Look
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-2">
          <div className="header-slider-heading-1">
          SAY GOODBYE TO  
          <br />
          COLD CALLS AND WAIT TIMES
          </div>
          <div className="header-slider-heading-2">
          Our grooming expert come at your Doortsep
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-3">
          <div className="header-slider-heading-1">
          GO HAIRLESS
          <br />
          RELAX, REJUVENATE!
          </div>
          <div className="header-slider-heading-2">
          Book our Professional Waxing Therapists
          </div>
        </div>
      </div>

      {/* --------------------------------- */}
      
      <div>
        <div className="header-slider header-slider-4">
          <div className="header-slider-heading-1">
          GIVE YOUR MATCHMAKER
            <br />
             AUNTIES A REASON FOR CHINCHAT
          </div>
          <div className="header-slider-heading-2">
          AUNTIES A REASON FOR CHINCHAT
          </div>
        </div>
      </div>

    </Slider>
  </div>
  )
}

export default HeaderSliderComponent