import React, { useState } from "react";
import { Link } from "react-router-dom";

const RecentBlogComponent = () => {
  const [show, setShow] = useState(false);

  return (
    <div>
      <div className="blog-page-col-2-blog">
        <div className="blog-page-col-2-heading">Top Blog</div>

        <div className="blog-page-col-2-content">
          <Link title="top-blog"
            target="blank"
            to="/blog/why-important-to-trim-pubic-hair"
            className="blog-page-col-2-content-list"
          >
            <div className="blog-page-col-2-content-heading">
              Why is it Important to Trim Pubic Hair?
            </div>
            <div className="blog-page-col-2-content-date">
              <span> Jan 13, 2020</span> <span>Admin</span>
            </div>
          </Link>

          <Link title="top-blog"
            target="blank"
            to="/blog/trim-your-pubic-hair"
            className="blog-page-col-2-content-list"
          >
            <div className="blog-page-col-2-content-heading">
              Pubic Hair Trimming makes your junk look bigger
            </div>
            <div className="blog-page-col-2-content-date">
              <span>Dec 28, 2019</span> <span>Admin</span>
            </div>
          </Link>

          <Link title="top-blog"
            target="blank"
            to="/blog/intimate-care"
            className="blog-page-col-2-content-list"
          >
            <div className="blog-page-col-2-content-heading">
              Seven Reasons Why You Need To Manscaped
            </div>
            <div className="blog-page-col-2-content-date">
              <span> Dec 13, 2019</span> <span>Admin</span>
            </div>
          </Link>

          <Link title="top-blog"
            target="blank"
            to="/blog/mens-grooming"
            className="blog-page-col-2-content-list"
          >
            <div className="blog-page-col-2-content-heading">
              Grooming Tips Every Men's Should Know
            </div>
            <div className="blog-page-col-2-content-date">
              <span> Nov 28, 2019</span> <span>Admin</span>
            </div>
          </Link>

          <Link title="top-blog"
            target="blank"
            to="/blog/face-beard-grooming"
            className="blog-page-col-2-content-list"
          >
            <div className="blog-page-col-2-content-heading">
              Face and Beard Grooming Tips
            </div>
            <div className="blog-page-col-2-content-date">
              <span> Nov 11, 2019</span> <span>Admin</span>
            </div>
          </Link>

          {!show ? (
            <div className="blog-page-col-2-content-show">
              <span
                onClick={(e) => {
                  setShow(!show);
                }}
              >
                view more blog
              </span>
            </div>
          ) : (
            ""
          )}

          {show ? (
            <>
              <Link title="top-blog"
                target="blank"
                to="/body-therapy"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  BODY MASSAGES THERAPY AT HOME
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Oct 28, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/brazilian-wax"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  BRAZILIAN WAXING
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Oct 13, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/event-party.php"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  EVENT AND PARTY GROOMING SERVICES
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Sep 28, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/full-body-polishing"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  FULL BODY POLISHING
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Sep 13, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/full-body-scrub"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  FULL BODY SCRUB
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Aug 28, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/full-body-wax"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  FULL BODY WAXING
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Aug 13, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/hair-colour.php"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  HAIR COLOUR AT HOME
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Jul 28, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/wedding-groomings.php"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  MEN'S PRE-WEDDING GROOMING
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Jul 13, 2019</span> <span>Admin</span>
                </div>
              </Link>

              
              <Link title="top-blog"
                target="blank"
                to="/honey-moon.php"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  HONEYMOON SPECIAL GROOMING SERVICES NEAR ME
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Jun 28, 2019</span> <span>Admin</span>
                </div>
              </Link>


              <Link title="top-blog"
                target="blank"
                to="/manicure-pedicure"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  MANICURE AND PEDICURE
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Jun 13, 2019</span> <span>Admin</span>
                </div>
              </Link>

              

              <Link title="top-blog"
                target="blank"
                to="/meeting-refresh.php"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  MEETING REFRESH
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> May 28, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/men-facial"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  MEN'S FACIALS
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> May 13, 2019</span> <span>Admin</span>
                </div>
              </Link>

              <Link title="top-blog"
                target="blank"
                to="/hair-spa"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  WHAT IS MEN’S HAIR SPA?
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Apr 28, 2019</span> <span>Admin</span>
                </div>
              </Link>


              <Link title="top-blog"
                target="blank"
                to="/lighten-the-dark-skin"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  Lighten The Dark Intimate Area
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Apr 13, 2019</span> <span>Admin</span>
                </div>
              </Link>


              <Link title="top-blog"
                target="blank"
                to="/pubic-hair-wax"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  PUBIC OR BRAZILIAN HAIR WAXING
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Mar 28, 2019</span> <span>Admin</span>
                </div>
              </Link>


              
              <Link title="top-blog"
                target="blank"
                to="/pre-wedding-groom"
                className="blog-page-col-2-content-list"
              >
                <div className="blog-page-col-2-content-heading">
                  MEN'S PRE WEDDING GROOMING
                </div>
                <div className="blog-page-col-2-content-date">
                  <span> Mar 13, 2019</span> <span>Admin</span>
                </div>
              </Link>


              {show ? (
            <div className="blog-page-col-2-content-show">
              <span
                onClick={(e) => {
                  setShow(!show);
                }}
              >
                view less blog
              </span>
            </div>
          ) : (
            ""
          )}
              
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentBlogComponent;
