import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import BlogBreadCrumbComponent from "../../components/user/BlogBreadCrumbComponent";
import img1 from "../../media/images/blog-img/bread-care-cor.png";

import RecentBlogComponent from "../../components/blog/RecentBlogComponent";
import BlogGlazmaComponent from "../../components/blog/BlogGlazmaComponent";
import { Helmet } from "react-helmet";

const  MeetingRefresh = () => {
  return (
    <div>


    <Helmet>
            <title>Blog - Glazma Men's Saloon - MEETING REFRESH</title>
            <meta
              name="description"
              content="
              A fresh and healthy surface is always better than one that is dull and bumpy. The way you look and groom will create a great impact on people you meet or work with.
             "
            />
          </Helmet>
      
      
      <Navbar />
      <BlogBreadCrumbComponent name=" Meeting Refresh" />

      <div className="blog-page">
        <div>
          <div className="single-blog">

            <h1 className="single-blog-heading">MEETING REFRESH</h1>
            <div className="single-blog-text">A fresh and healthy surface is always better than one that is dull and bumpy. The way you look and groom will create a great impact on people you meet or work with. Professional appearance and proper grooming are the vital things for working men.</div>

            <div className="single-blog-img">
              <img title="blog-img"  src={img1} alt="blog-img" />
            </div>

            <div className="single-blog-heading-1">Get a Refresh Look</div>
            <div className="single-blog-points">
              <ul>
                <li>When corporate meeting in another city</li>
                <li>When go to meet someone special</li>
                <li>When official party organised</li>
                <li>when travelling in another city</li>
              </ul>
            </div>

            <div className="single-blog-text-1">For any men’s grooming services need, visit our website and simply fill appointment form with scheduled date and preferred time. Our gents grooming experts will come at your place to give a refresh look.
<br />
Currently this facility is available in Noida, Noida Extension, Greater Noida, Ghaziabad, Indirapuram, Rajnagar Extension, Faridabad, Gurgaon, Delhi -NCR also. You call/WhatsApp at 9658-153-153 us at your home, office or hotel you stay in city.</div>


            <div className="single-blog-heading-1">What Includes in Meeting Refresh Service Package</div>
            <div className="single-blog-points">
              <ul>
                <li>Face Scrub</li>
                <li>Face Massage</li>
                <li>Peel-Off Mask</li>
                <li>Hair Styling</li>
              </ul>
            </div>
            
          </div>

          <div className="blog-page-col-2">
            <BlogGlazmaComponent />
            <RecentBlogComponent />
          </div>
        </div>
      </div>

      <LocationComponent />
      <FooterComponent />
    </div>
  )
}

export default MeetingRefresh