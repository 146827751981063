import React from 'react'

const NavTop2Component = () => {
  return (
    <div>
        <div className="nav-top-2">
            <span>
        Get Upto 50% Off On All Services
            </span>
            <div>*Limited Period Offer</div>
        </div>
    </div>
  )
}

export default NavTop2Component