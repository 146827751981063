import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import BlogBreadCrumbComponent from "../../components/user/BlogBreadCrumbComponent";

import RecentBlogComponent from "../../components/blog/RecentBlogComponent";
import BlogGlazmaComponent from "../../components/blog/BlogGlazmaComponent";
import { Helmet } from "react-helmet";

const MensPreWeddinGroomingServicesNearMe = () => {
  return (
    <div>


    <Helmet>
            <title>Blog - Glazma Men's Saloon - MEN'S PRE WEDDING GROOMING</title>
            <meta
              name="description"
              content="
              On your wedding day , you'll want to look like the best version of yourself ? The bride always steals the attraction at a wedding .
             "
            />
          </Helmet>
      
      
      <Navbar />
      <BlogBreadCrumbComponent name="Men’s Pre Wedding Grooming Services Near Me" />

      <div className="blog-page">
        <div>
          <div className="single-blog">

            <h1 className="single-blog-heading">MEN'S PRE WEDDING GROOMING</h1>
            <div className="single-blog-text">
            On your wedding day, you'll want to look like the best version of yourself? The bride always steals the attraction at a wedding, but now days it’s time for the groom to claim an equal space at wedding. We are in way to changing the mindset of the Indian man.
<br />
We provide doorstep services for pre-wedding groom services packages and various arrangements made for the groom. Our fashionable and trained stylist beauty artists and pre-wedding groom service professionals will help you look and feel like a king on your big day. We also pamper your guests with an assortment of services to ensure they too look their best.
            </div>


            <div className="single-blog-heading-1">We do - Pre wedding Grooming</div>
            <div className="single-blog-points">
              <ul>
              <li>Full Body Wax</li>
              <li>Full Body Polish</li>
              <li>Pedicure and Manicure</li>
              <li>Faicial and Detan O3+</li>
              <li>Brazilian Waxing</li>
              <li>Haldi Chandan Pack</li>
              <li>Lighten The Dark Skin</li>
              <li>Full Body Detan</li>
              </ul>
            </div>

            <div className="single-blog-text-1">Start your celebration by booking a service with Glazma -wedding groom services near me salon, today for your pre-wedding and wedding packages!. Call us on +91 9658-153-153 or write to us at info@glazma.com to know about our plans and pricing.</div>

            
          </div>

          <div className="blog-page-col-2">
            <BlogGlazmaComponent />
            <RecentBlogComponent />
          </div>
        </div>
      </div>

      <LocationComponent />
      <FooterComponent />
    </div>
  )
}

export default MensPreWeddinGroomingServicesNearMe