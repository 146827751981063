import React from 'react'
import Navbar from '../../components/user/Navbar'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'
import { Helmet } from "react-helmet";
import BlogPageComponent from '../blog/BlogPageComponent'

const BlogPage = () => {
  return (
    <div>

<Helmet>
        <title>Blog - Glazma Men's Saloon - Save More, Care Better</title>
        <meta
          name="description"
          content="
          Read latest blog and articles about men's grooming, waxing and men's care and get updated. Glazma offers grooming and waxing service by experts at your location
         "
        />
      </Helmet>
      
        <Navbar/>
        <BreadCrumbComponent name="blog" title="Glazma Men's Saloon - Blog"/>

        <BlogPageComponent/>
        
        <LocationComponent/>
        <FooterComponent/>
    </div>
  )
}

export default BlogPage