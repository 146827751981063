import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  getCartTotal,
  removeItem,
  decreaseItemQuantity,
  increaseItemQuantity,
} from "../../store/cartSlice";
import ConfirmBookingForm from "./ConfirmBookingForm";

const CartComponent = () => {
  const [cartList, setCartList] = useState(false);

  const [confirmForm, setConfirmForm] = useState(false)
  
  // --------------------------------------------------------------------------------------------

  const { cart, totalQuantity, totalPrice } = useSelector(
    (state) => state.allCart
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartTotal());
  }, [cart]);

  // console.log("first", cart)

  return (
    <div>
      {
        confirmForm?
        <ConfirmBookingForm setConfirmForm={setConfirmForm} crt={cart}/>
:""
      }
      {cart.length <= 0 ? (
        ""
      ) : (
        <div className="cart-fix-col">
          {/* <div className="cart-fix-col-text">
            Total Amount : <span> Rs. 1000 /-</span>
          </div> */}
          <div className="cart-fix-button" onClick={(e) => setCartList(!cartList)}>
            <div>{cart.length}</div>
            {/* {totalQuantity} */}
            <i className="fa-solid fa-cart-shopping"></i>
          </div>
        </div>
      )}

      {/* ------------------------------------------------------------- */}

      {cartList ? (
        <div className="cart-content">
          <div className="cart-content-main">
            <div className="cart-content-heading">
              <div>&nbsp; My Cart ({cart.length} services)</div>
              <div>
                <i
                  className="fa-solid fa-circle-xmark"
                  onClick={(e) => setCartList(!cartList)}
                ></i>
              </div>
            </div>

            {/* ----------------------------------------- */}
            <div className="cart-content-list">
              {cart?.map((data) => (
                <div className="cart-content-list-col">
                  <div className="cart-content-list-service">
                    <div className="cart-content-list-service-text">
                      <div className="cart-content-list-service-name">
                        {data.fullName}
                      </div>
                      <div className="cart-content-list-service-price">
                        Rs. {data.price}/-
                      </div>
                    </div>

                    <div className="cart-content-list-service-buttons">
                      <span>
                        {data.quantity <= 1 ? (
                          <div>&nbsp; &nbsp; &nbsp; &nbsp;</div>
                        ) : (
                          <div
                            className="cart-content-list-service-btn"
                            onClick={() =>
                              dispatch(decreaseItemQuantity(data._id))
                            }
                          >
                            -
                          </div>
                        )}
                      </span>
                      <div className="cart-content-list-service-qun">
                        {data.quantity}
                      </div>

                      {data.quantity >= 10 ? (
                        ""
                      ) : (
                        <div
                          className="cart-content-list-service-btn"
                          onClick={() =>
                            dispatch(increaseItemQuantity(data._id))
                          }
                        >
                          +
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="cart-content-list-price">
                    <i
                      className="fa-solid fa-trash"
                      onClick={() => dispatch(removeItem(data._id))}
                    ></i>
                  </div>
                  <hr />
                </div>
              ))}
            </div>

            {/* ----------------------------------------- */}

            <div className="cart-content-total-main">
              <div className="cart-content-total">
                <div className="cart-content-total-content">
                  <div className="cart-content-total-text">
                    Total Quantity :
                  </div>
                  <div className="cart-content-total-price">
                    {totalQuantity}
                  </div>
                </div>
                <div className="cart-content-total-content">
                  <div className="cart-content-total-text">Total Price :</div>
                  <div className="cart-content-total-price">
                    Rs. {totalPrice}/-
                  </div>
                </div>
              </div>
            </div>

            <div className="cart-content-checkout"  onClick={e=> setConfirmForm(true)}>Check Out</div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CartComponent;
