import React, { useState } from 'react';
import blogData from './DataBlog.json';
import { Link } from 'react-router-dom';
import BlogGlazmaComponent from '../../components/blog/BlogGlazmaComponent';
import RecentBlogComponent from '../../components/blog/RecentBlogComponent';

const BlogPageComponent = () => {

    
    const [currentPage, setCurrentPage] = useState(1)
    const recordPerPage = 15;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = blogData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(blogData.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)


    const prevPage = e => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
    const changeCPage = e => {
        setCurrentPage(e)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const nextPage = e => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }

    
  return (
    <div>

<div className="blog-page">
        <div>
          <div className="blog-page-col-1">
  {
                                records.map(e => (
            <div className="blog-section">
              <div className="blog-section-img blog-section-img"><img title={e.title} alt="blog-img" src={`${e.img}`}  /> </div>
              <div>
              <div className="blog-section-head">
                <div className="blog-section-head-date">{e.date}</div>
                <div className="blog-section-head-admin">ADMIN</div>
              </div>
              <div className="blog-section-content">
                <div className="blog-section-content-heading">{e.title}</div>
                <div className="blog-section-content-text">{e.text}</div>
                <div className="blog-section-content-button">
                  <Link title={e.title} target="_blank" to={e.link}>Read more...</Link>
                </div>
              </div>
            </div>

            </div>
            

        ))
    }

<div className="blog-component-col-2-pagination">
                            <div className="blog-component-col-2-pagination-buttons ">
                                <div className={`blog-component-col-2-pagination-btn ${currentPage === 1 ? 'blog-component-col-2-pagination-btn-disable' : ''}`} onClick={prevPage}><i className="fa-solid fa-angle-left"></i></div>
                                {
                                    numbers.map((n, i) => (
                                        <div className={`blog-component-col-2-pagination-btn ${currentPage === n ? 'blog-component-col-2-pagination-btn-active' : ''}`} key={i} onClick={() => changeCPage(n)}>{n}</div>
                                    ))
                                }


                                <div className={`blog-component-col-2-pagination-btn ${currentPage === numbers.length ? 'blog-component-col-2-pagination-btn-disable' : ''}`} onClick={nextPage}><i className="fa-solid fa-angle-right"></i></div>

                            </div>

                        </div>



          </div>


          <div className="blog-page-col-2">
          <BlogGlazmaComponent/>
            <RecentBlogComponent/>
            </div>
        </div>
      </div>

    </div>
  )
}

export default BlogPageComponent