import React from "react";
import CountUp from "react-countup";

// import img1 from "../../media/images/Pubic-Hair-Removal-For-Men.png"
// import img2 from "../../media/images/Male-Full-Body-Wax-Near-Me.png"
// import img3 from "../../media/images/Full-Body-Waxing-For-Men.png"
// import img4 from "../../media/images/Waxing-For-Men-Delhi.png"

const NumberIncrementComponent = () => {
  return (
    <div>
      <div className="increment">
    <div>
        <div className="increment-list">

          <div className="increment-list-col">
            {/* <div className="increment-list-col-head"><span><img src={img4} alt="img" /> &nbsp; <CountUp end={10} duration="4" enableScrollSpy /> +</span></div> */}
            <div className="increment-list-col-head"><span> &nbsp; <CountUp end={10} duration="4" enableScrollSpy /> +</span></div>
            <div className="increment-list-col-text">Years Of Experience</div>
          </div>


          <div className="increment-list-col">
            {/* <div className="increment-list-col-head"><span><img src={img1} alt="img" /> &nbsp; <CountUp end={15} duration="4" enableScrollSpy /> +</span></div> */}
            <div className="increment-list-col-head"><span> &nbsp; <CountUp end={15} duration="4" enableScrollSpy /> +</span></div>
            <div className="increment-list-col-text">Available Cities</div>
          </div>


          <div className="increment-list-col">
            {/* <div className="increment-list-col-head"><span><img src={img3} alt="img" /> &nbsp; <CountUp end={100} duration="4" enableScrollSpy /> +</span></div> */}
            <div className="increment-list-col-head"><span> &nbsp; <CountUp end={100} duration="4" enableScrollSpy /> +</span></div>
            <div className="increment-list-col-text">Beauty Expert</div>
          </div>


          <div className="increment-list-col">
            {/* <div className="increment-list-col-head"><span><img src={img2} alt="img" /> &nbsp; <CountUp end={50000} duration="4" enableScrollSpy /> +</span></div> */}
            <div className="increment-list-col-head"><span> &nbsp; <CountUp end={100000} duration="4" enableScrollSpy /> +</span></div>
            <div className="increment-list-col-text">Happy Customers</div>
          </div>

        </div>
      </div>
    </div>
    </div>
  );
};

export default NumberIncrementComponent;
