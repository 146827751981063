import React from "react";
import { Link } from "react-router-dom";

const BreadCrumbComponent = ({name, title}) => {
  return (
    <div>
      <div className="bread-crumb">
        {
          title?
          <h1 className="bread-crumb-heading">{title}</h1>
          :
          <div className="bread-crumb-heading">{name}</div>
        }
        <div className="bread-crumb-path">
          <Link to="/">Home</Link> &nbsp; / &nbsp; <span>{name}</span>
        </div>
      </div>
    </div>
  );
};

export default BreadCrumbComponent;
