import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";

const ContactComponent = () => {
  const [loading, setLoading] = useState(false);

  // post ----------------------------------------------------------

  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: "",
    phone: "",
    address: "",
    subject: "",
    message: "",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;

    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      const response = await fetch("https://api.shop.glazma.com/contact-us", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        // setBookingForm(false);
        alert("Service booked successfully");
        navigate("/contact-confirm");
      } else {
        // alert("invalid credential...");
        const res_data = await response.json();
        alert(res_data.message);
        // console.log("className=",res_data.message)
      }

      // console.log(response);
    } catch (error) {
      // console.log("catch err", error);
      alert("server error");
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className="contact-mn">
        <div className="contact-page">
          {/* col-1 */}
          <div className="contact-col-1">
            <div className="cont-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.014336785242!2d77.3256289!3d28.569332199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5bc6d74074b%3A0x5de0b5d8853aef45!2sGlazma%20-Men&#39;s%20Salon%20at%20Home!5e0!3m2!1sen!2sin!4v1710507028198!5m2!1sen!2sin"
                allowfullscreen=""
                title="goc"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>

          {/* col-2 */}
          <div className="contact-col-2">
            <h1 className="contact-col-2-h1">Glazma - Contact Us</h1>
            <div className="contact-col-2-h2">Welcome to GLAZMA</div>
            <h2 className="contact-col-2-h3">We're here to help you</h2>
            <div className="contact-col-2-h4">
              Fill out the form to contact our team.
            </div>
            {/* contact form */}
            <form className="contact-frm" onSubmit={handleSubmit}>
              <div>
                <input
                  required
                  placeholder="* Your Name "
                  name="name"
                  onChange={handleChange}
                />
                <input
                  required
                  placeholder="* Your Number"
                  type="number"
                  name="phone"
                  onChange={handleChange}
                />
              </div>
              <input
                required
                placeholder="Your Address or City"
                name="address"
                onChange={handleChange}
              />
              <input
                required
                placeholder="Subject"
                name="subject"
                onChange={handleChange}
              />
              <textarea
                required
                rows="3"
                placeholder="* How can we help you?"
                name="message"
                onChange={handleChange}
              />
              <button>Contact</button>
            </form>
            {/* form end */}
          </div>
          {/* col-2 end */}
        </div>

        <div className="contact-top">
          {/* c-t-col-1 */}
          <div className="c-t-col">
            <div className="c-t-icon">
              <i className="fa-solid fa-location-dot"></i>
            </div>
            <div className="c-t-text">
              <div className="c-t-t-h">Location :</div>
              <div className="c-t-t-t">
                
                {/* 210, 2nd Floor, Ocean Plaza,  */}
                520, 5th Floor, Ocean Complex,<br />
                Sector-18, Noida - 201301
              </div>
            </div>
          </div>

          {/* c-t-col-3 */}
          <div className="c-t-col">
            <div className="c-t-icon">
              <i className="fa-solid fa-phone-volume"></i>
            </div>
            <div className="c-t-text">
              <div className="c-t-t-h">Call :</div>
              <div className="c-t-t-t"> 0120 4116153</div>
            </div>
          </div>

          {/* c-t-col-2 */}
          <div className="c-t-col">
            <div className="c-t-icon">
              <i className="fa-solid fa-envelope-open-text"></i>
            </div>
            <div className="c-t-text">
              <div className="c-t-t-h">Email :</div>
              <div className="c-t-t-t">info@glazma.com</div>
            </div>
          </div>

          {/* c-t-col-4 */}
          <div className="c-t-col">
            <div className="c-t-icon">
              <i className="fa-brands fa-whatsapp"></i>
            </div>
            <div className="c-t-text">
              <div className="c-t-t-h">WhatsApp :</div>
              <div className="c-t-t-t"> +91 9658-153-153</div>
            </div>
          </div>

          {/* <div className='contact-connect'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore atque quibusdam ipsum, veritatis ea soluta cum eveniet, laboriosam asperiores repellendus odio reprehenderit, alias mollitia consequuntur.</div> */}
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;
