import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPageComponent = () => {
  return (
    <div>
        <div className="error-page">
            <div>
                <div className="error-page-heading">404 Not Found</div>
                <div className="error-page-text">Your visited page not found. You may go home page.</div>
                <div className="error-page-button"><Link to="/">Back to home page</Link></div>
            </div>
        </div>
    </div>
  )
}

export default ErrorPageComponent