import React from 'react'
import { Link } from 'react-router-dom'

const BlogBreadCrumbComponent = ({name}) => {
  return (
    <div>
        <div className="bread-crumb">
        <div className="bread-crumb-heading">{name}</div>
        <div className="bread-crumb-path">
          <Link to="/">Home</Link> &nbsp; / &nbsp; <Link to="/blog">Blog</Link> &nbsp; / &nbsp; <span>{name}</span>
        </div>
      </div>
    </div>
  )
}

export default BlogBreadCrumbComponent