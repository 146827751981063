import React from "react";
import { Link } from "react-router-dom";
import google from "../../media/images/google-icon.png";

const TestimonialComponent = () => {
  return (
    <div>
      <div className="home-container-6">
        <div className="home-container-6-heading">CUSTOMERS SPEAKS</div>
        <div className="home-container-6-head">
          <div className="home-container-6-head-col-1">
            <div className="home-container-6-head-col-1-heading-1">
              Customer testimonial on Google
            </div>
            <div className="home-container-6-head-col-1-heading-2">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              &nbsp;4.9 Rating on Google
            </div>
          </div>
          <div className="home-container-6-head-col-2">
            <Link title="Bikini Line Hair Removal"
              to="https://www.google.com/search?kgmid=/g/11jjjvrtd1&hl=en-IN&q=Glazma+-Men%27s+Salon+at+Home&kgs=0b9fe95014d0537e&shndl=17&source=sh/x/kp/osrp/m5/1#lrd=0x390ce5bc6d74074b:0x5de0b5d8853aef45,1,,,,"
              target="_blank"
            >
              Review us on Google
            </Link>
          </div>
        </div>
        <div className="home-container-6-content">
          <div className="home-container-6-content-cols">
            <div className="home-container-6-content-col-1">
              <div className="home-container-6-content-cos-1-google">
                <img title="google-icon" src={google} alt="google-icon" />
              </div>
              <div className="home-container-6-content-cos-1-stars">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <div className="home-container-6-content-cos-1-text">
                Had a great experience using the services from Aman Bakhsh,
                Glazma... Full cooperation and suggested best possible ways to
                take care of all requirements. Especially for the first...
                <br />
                <Link title="Best Waxing Near Me" target="blank" to="https://g.co/kgs/K2S5md9">
                  read more on Google...
                </Link>
              </div>
              <div className="home-container-6-content-cos-1-name">
                Ash Zutshi
              </div>
              <div className="home-container-6-content-cos-1-date">
                2 years ago
              </div>
              <div className="home-container-6-content-cos-1-img">
                <img title="user icon"
                  src="https://lh3.googleusercontent.com/a-/ALV-UjX9rICTgRfPNz3UEVOBJA3CX8uqfv7_YNTmE3hRr26JKQ=w45-h45-p-rp-mo-br100"
                  alt="user-icon"
                />
              </div>
            </div>

            <div className="home-container-6-content-col-1">
              <div className="home-container-6-content-cos-1-google">
                <img title="google-icon" src={google} alt="google-icon" />
              </div>
              <div className="home-container-6-content-cos-1-stars">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <div className="home-container-6-content-cos-1-text">
                Glazma men's I’ve tried many of their services and I’ve always
                been happy with them. Very friendly and professional both Aman &
                Aditya. They offer a very good...
                <br />
                <Link title="Hair Waxing Near Me" target="blank" to="https://g.co/kgs/zbqWqqM">
                  read more on Google...
                </Link>
              </div>
              <div className="home-container-6-content-cos-1-name">
                Rahul Singh
              </div>
              <div className="home-container-6-content-cos-1-date">
                a year ago
              </div>
              <div className="home-container-6-content-cos-1-img">
                <img title="user icon"
                  src="https://lh3.googleusercontent.com/a-/ALV-UjUTgdpwtz3Hwm4qu0oN5a9_38wj-WcQ8qEq2QyzkCgXtQg=w45-h45-p-rp-mo-br100"
                  alt="user-icon"
                />
              </div>
            </div>

            <div className="home-container-6-content-col-1">
              <div className="home-container-6-content-cos-1-google">
                <img title="google-icon" src={google} alt="google-icon" />
              </div>
              <div className="home-container-6-content-cos-1-stars">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <div className="home-container-6-content-cos-1-text">
                at home marriage grooming services for my friend were absolutely
                outstanding! The team was professional, punctual, and incredibly
                talented. He looked dashing on...
                <br />
                <Link title="Wax Appointment" target="blank" to="https://g.co/kgs/naXaEbW">
                  read more on Google...
                </Link>
              </div>
              <div className="home-container-6-content-cos-1-name">
                Manoj singh Rajawat
              </div>
              <div className="home-container-6-content-cos-1-date">
                3 months ago
              </div>
              <div className="home-container-6-content-cos-1-img">
                <img title="user icon"
                  src="https://lh3.googleusercontent.com/a/ACg8ocJz_yR5-tC1BWZIEpVoOg6Qpp6EniuvIRxpuwCZfUOx=w45-h45-p-rp-mo-br100"
                  alt="user-icon"
                />
              </div>
            </div>

            <div className="home-container-6-content-col-1">
              <div className="home-container-6-content-cos-1-google">
                <img title="google-icon" src={google} alt="google-icon" />
              </div>
              <div className="home-container-6-content-cos-1-stars">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <div className="home-container-6-content-cos-1-text">
                Glazma whole team brought professionalism, precision, and
                convenience right to my doorstep. He made this sensitive
                grooming process comfortable and...
                <br />
                <Link title="Manzilian Wax"
                  target="blank"
                  to="https://maps.app.goo.gl/CCqzpX5CFvDwBSJQ7"
                >
                  read more on Google...
                </Link>
              </div>
              <div className="home-container-6-content-cos-1-name">
                Romeo 444
              </div>
              <div className="home-container-6-content-cos-1-date">
                3 months ago
              </div>
              <div className="home-container-6-content-cos-1-img">
                <img title="user icon"
                  src="https://lh3.googleusercontent.com/a-/ALV-UjXX2hGKuDqC-IwxrOgMAGct09AqndBR5qLOyA-EgzFjQg=w45-h45-p-rp-mo-br100"
                  alt="user-icon"
                />
              </div>
            </div>

            <div className="home-container-6-content-col-1">
              <div className="home-container-6-content-cos-1-google">
                <img title="google-icon" src={google} alt="google-icon" />
              </div>
              <div className="home-container-6-content-cos-1-stars">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </div>
              <div className="home-container-6-content-cos-1-text">
                The Men's Manicure and Pedicure service by Glazma Salon is a
                total game-changer! They brought the spa experience right to my
                doorstep, and I felt...
                <br />
                <Link title="Wax Studio Near Me"
                  target="blank"
                  to="https://maps.app.goo.gl/ZMvbz7AdHu2dRWXL6"
                >
                  read more on Google...
                </Link>
              </div>
              <div className="home-container-6-content-cos-1-name">
                Lokesh Gautam
              </div>
              <div className="home-container-6-content-cos-1-date">
                2 months ago
              </div>
              <div className="home-container-6-content-cos-1-img">
                <img title="user icon"
                  src="https://lh3.googleusercontent.com/a-/ALV-UjXmMr2SqWaOilG5BC9T_yh6iEZ9cT4ZQ34ob1nGCc-zYA=w45-h45-p-rp-mo-br100"
                  alt="user-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialComponent;
