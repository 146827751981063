import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import BlogBreadCrumbComponent from "../../components/user/BlogBreadCrumbComponent";

import RecentBlogComponent from "../../components/blog/RecentBlogComponent";
import BlogGlazmaComponent from "../../components/blog/BlogGlazmaComponent";
import { Helmet } from "react-helmet";

const EventPartyGrooming = () => {
  return (
    <div>


    <Helmet>
            <title>EVENT AND PARTY GROOMING SERVICES</title>
            <meta
              name="description"
              content="
              If you go for a party or event, whatever the occasion, our male grooming services salon experts give you a best look of yours and turn your events
             "
            />
          </Helmet>
      
      
      <Navbar />
      <BlogBreadCrumbComponent name="Event/Party Grooming" />

      <div className="blog-page">
        <div>
          <div className="single-blog">

            <h1 className="single-blog-heading">EVENT AND PARTY GROOMING SERVICES</h1>
            <div className="single-blog-text">
            If you go for a party or event, whatever the occasion, our male grooming services salon experts give you a best look of yours and turn your events, corporate parties into a stellar success. Party season is fast approaching, and although when it comes to party preparation, we tend to only consider women, men shouldn’t forget their grooming rituals either.
<br />
Want to pep up your look before an event or party? We will help and groom men's to be well prepared, and look your best at every office party, gatherings and Holiday party you may be attending and make sure you get the look you want.
            </div>


            <div className="single-blog-heading-1">Look your best with Glazma Men’s Salon Services Near Me</div>
            <div className="single-blog-points">
              <ul>
              <li>When attend corporate party</li>
              <li>When attend any birthday party</li>
              <li>At any society holiday party</li>
              <li>When go for any event</li>
              <li>When go for any interview</li>
              <li>When go to face camera for modelling or acting or any success party</li>
              <li>At product launches, fashion shows and exhibitions</li>
              <li>When attend press releases</li>

              </ul>
            </div>

            <div className="single-blog-text-1">
            We always keep our client’s identity and data privacy secure. Simply fill our appointment form. Glazma -Men’s beauty services near me salon professionals will come at your place with modern salon equipment’s to give you a world class men’s grooming service.

<br />
Currently this facility is available in Noida, Noida Extension, Greater Noida, Ghaziabad, Indirapuram, Rajnagar Extension, Faridabad, Gurgaon and Delhi -NCR also.
<br />
Call/WhatsApp us on +91 9658-153-153 or write to us at info@glazma.com to know about our packages and pricing at your home, office or hotel you stay in city.
            </div>

            <div className="single-blog-heading-1">What Includes in Even and Party Grooming Services Nearby Me</div>

            <div className="single-blog-points">
              <ul>
              <li>Face Clean-up</li>
              <li>Facial VLCC</li>
              <li>Manicure</li>
              <li>Pedicure</li>
              <li>Head Massage</li>
              <li>Nail Cutting</li>
              </ul>
            </div>

            
          </div>

          <div className="blog-page-col-2">
            <BlogGlazmaComponent />
            <RecentBlogComponent />
          </div>
        </div>
      </div>

      <LocationComponent />
      <FooterComponent />
    </div>
  )
}

export default EventPartyGrooming