import React from 'react'
import { Link } from 'react-router-dom'

const AdminHomeComponent = () => {
  return (
    <div>
        {/* <div className='admin-home-heading'>Welcome to Glazma Dashboard</div> */}

<div className="admin-home-list">
  <div>
<Link to="/admin/orders"><i class="fa-solid fa-book-atlas"></i><div>Orders</div></Link>
<Link to="/admin/inquiry"><i class="fa-solid fa-clipboard-question"></i><div>Popup Inquiry</div></Link>
<Link to="/admin/contact-us"><i class="fa-solid fa-file-contract"></i><div>Contact Us Queries</div></Link>

  </div>

</div>

    </div>
  )
}

export default AdminHomeComponent