import React from "react";
import img from "../../media/images/Full-Body-Hair-Removal-For-Male-Salon.png";

const FranchiseComponent = () => {
  return (
    <div>
      <div className="franchise">
        <div>
          <div className="franchise-head">
            <div className="franchise-head-heading-1">THE FOUNDATION</div>
            <h1 className="franchise-head-heading-2">
              WHERE MEN WILL BE A GENTLEMEN
            </h1>
            <div className="franchise-head-icon">
              <img src={img} alt="Male Waxing Near Me" title="Waxing Near Me" />
            </div>
            <div className="franchise-head-text">
              You find too many brands in salon segment among them 90% are
              unisex in India. We understand that approach, But our strength,
              atmosphere and servicing offerings are different we core focus and
              dedicated our services to MEN'S only.
              <br />
              <br />
              We have 3 franchise models in India as per investors investment.
              <br />
              We have 3 franchise models in India as per Investment. Our
              centralised Marketing & CRM team work 24*7 for customer
              satisfaction and creating a high brand value and word class
              services. We ar assuring our investors a high returns on thir
              investments.
            </div>
          </div>
        </div>
      </div>

      <div className="franchise-step">
        <div>
          <div className="franchise-step-heading-1">STEPS</div>
          <div className="franchise-step-heading-2">TO GET OUR FRANCHISE</div>
          <div className="franchise-step-icon">
            <img
              src={img}
              alt="Pubic Hair Removal"
              title="Male Hair Removal Near Me"
            />
          </div>
          <div className="franchise-step-heading-3">
            "The Journey of a thousand miles begins with one step" - We have 4
            steps
          </div>
          <div className="franchise-step-content">
            <div className="franchise-step-content-col">
              <div className="franchise-step-content-col-heading">
                <span>STEP ONE</span>
                <br />
                BRAND OVERVIEW PRESENTATION
              </div>
              <div className="franchise-step-content-col-text">
                Our Business developemnt head will hold in-person or web based
                presentation about GLAZMA- concept, process, marketing and
                mission vsion. We also share our standard franchise revenue
                model.
              </div>
            </div>

            <div className="franchise-step-content-col">
              <div className="franchise-step-content-col-heading">
                <span>STEP TWO</span>
                <br />
                QUESTIONNAIRE AND FINDINGS
              </div>
              <div className="franchise-step-content-col-text">
                After questionare related to business and investments we will
                send you our guidelines and requirements. As we move forward we
                need a plan of marketing and Outlet designing blue print.
              </div>
            </div>

            <div className="franchise-step-content-col">
              <div className="franchise-step-content-col-heading">
                <span>STEP THREE</span>
                <br />
                EVALUATION & DISCOVERY
              </div>
              <div className="franchise-step-content-col-text">
                We'll review your market demographics, discuss site selection
                and go over the pre-opening process then We will schedule a
                meeting at our corporate office for marketing and drafting
                revenue model.
              </div>
            </div>

            <div className="franchise-step-content-col">
              <div className="franchise-step-content-col-heading">
                <span>STEP FOUR</span>
                <br />
                DOCUMENTATION & AGREEMENT
              </div>
              <div className="franchise-step-content-col-text">
                Once your application has been approved by our management team,
                we'll process franchise Agreement and related documents and send
                you for cross check after all formalities it will be signed and
                stamped.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="franchise-contact">
        <div className="franchise-contact-heading">FRANCHISE CONTACT</div>
        <div className="franchise-contact-content">
          <div className="franchise-contact-content-col-1">
            <iframe
              title="contact-location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0141807041764!2d77.32305397455468!3d28.569336886949426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5bc6d74074b%3A0x5de0b5d8853aef45!2sGlazma%20-Men&#39;s%20Salon%20at%20Home!5e0!3m2!1sen!2sin!4v1717495620796!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="franchise-contact-content-col-2">
            <div className="franchise-contact-content-col-2-heading">
              Contact Details
            </div>
            <div className="franchise-contact-content-col-2-details">
              <div className="franchise-contact-content-col-2-point">
                <div className="franchise-contact-content-col-2-point-heading">
                  Address :
                </div>
                <div className="franchise-contact-content-col-2-point-text">
                {/* 210, 2nd Floor, Ocean Plaza,  */}
                520, 5th Floor, Ocean Complex, Sector-18, Noida - 201301
                  
                </div>
              </div>

              <div className="franchise-contact-content-col-2-point">
                <div className="franchise-contact-content-col-2-point-heading">
                  Telephone number :
                </div>
                <div className="franchise-contact-content-col-2-point-text">
                  0120 4116153
                </div>
              </div>

              <div className="franchise-contact-content-col-2-point">
                <div className="franchise-contact-content-col-2-point-heading">
                  Email :
                </div>
                <div className="franchise-contact-content-col-2-point-text">
                  info@glazma.com
                </div>
              </div>

              <div className="franchise-contact-content-col-2-point">
                <div className="franchise-contact-content-col-2-point-heading">
                  Phone number :{" "}
                </div>
                <div className="franchise-contact-content-col-2-point-text">
                  +91 9658-153-153
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div>
            <div className="franchise-contact-heading">FRANCHISE CONTACT</div>
            <div className="franchise-contact-text">Please fill out the form below and a member of our team will be in touch with you.</div>
            <div className="franchise-contact-form">
                <form>
                    <div>
                        <input type="text" placeholder="Name" />
                        <input type="text" placeholder="Phone no" />
                    </div>
                        <input type="text" placeholder="Email Address" />
                    <div>
                        <input type="text" placeholder="City" />
                        <input type="text" placeholder="Area" />
                    </div>
                    <div>
                        <input type="text" placeholder="Postal Code" />
                        <input type="text" placeholder="State" />
                    </div>

                    <div>
                        <select name="" id="">
                            <option selected disabled value="">Sign a franchise agreement within</option>
                            <option value="">1 months</option>
                            <option value="">3 months</option>
                            <option value="">6 months</option>
                            <option value="">12+ months</option>
                        </select>

                        <select name="" id="">
                            <option selected disabled value="">Capital to Invest</option>
                            <option value="">INR 10 Lacs</option>
                            <option value="">INR 15 Lacs</option>
                            <option value="">INR 20 Lacs</option>
                        </select>

                        
                    </div>
                        <button>Send</button>
                    
                </form>
            </div>
        </div> */}
      </div>
    </div>
  );
};

export default FranchiseComponent;
