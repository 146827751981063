import React from 'react'
import Navbar from '../../components/user/Navbar'
import ErrorPageComponent from '../../components/user/ErrorPageComponent'
import FooterComponent from '../../components/user/FooterComponent'

const ErrorPage = () => {
  return (
    <div>
        <Navbar/>
        <ErrorPageComponent/>
        <FooterComponent/>
    </div>
  )
}

export default ErrorPage