import React from 'react'
import img from "../../media/images/Full-Body-Wax-Price.png";

import icon1 from "../../media/images/Full-Body-Wax-Price-In-Delhi.png"
import icn2 from "../../media/images/facial icon.png"
import icn7 from "../../media/images/icon-3.png"
import icn10 from "../../media/images/cleanup,facial,scrub icon.png"

const WeddingGroomPackagesComponent = () => {
  return (
    <div>
        
        <div className="home-package">
        <div>
          <div className="home-package-heading-1">
          WEDDING GROOMING PACKAGES
          </div>
          <div className="home-package-head-icon">
            <img src={img} alt="" />
          </div>
          <div className="home-package-head-text">
          Elevate your pre-wedding grooming experience with our exclusive Wedding Groom Salon Package, meticulously crafted to cater to your every grooming need, right in the comfort of your home. This comprehensive package merges traditional grooming rituals with modern skincare techniques, ensuring you radiate confidence and charm on your special day.

            
          </div>
          <div className="home-package-wedding-content">

            <div className="home-package-list">
              <div className="home-package-icon"><img src={icon1} alt="" /></div>
              <div className="home-package-heading">Men’s Regular Groom Package</div>
              <div className="home-package-text">Full Body Polish (Steps -Cleanser, Scrub, Massage, Pack, Serum), Full Body Hair Removal, Pubic Hair Removal</div>
              <div className="home-package-price"><s>Rs. 12798</s> 7999</div>
            </div>


            <div className="home-package-list">
              <div className="home-package-icon"><img src={icn2} alt="" /></div>
              <div className="home-package-heading">Men’s Exclusive Groom Package</div>
              <div className="home-package-text">Facial & Detan, Full Body Polish (Steps -Cleanser, Scrub, Massage, Pack, Serum), Full Body Hair Removal, Pubic Hair Removal</div>
              <div className="home-package-price"><s>Rs. 15997</s> 9999</div>
            </div>


            <div className="home-package-list">
              <div className="home-package-icon"><img src={icn10} alt="" /></div>
              <div className="home-package-heading">Men’s Premium Groom Package (Single Sitting)</div>
              <div className="home-package-text">Facial O3+ With Detan O3+, Full Body Polish (Steps -Cleanser, Scrub, Massage, Pack, Serum), Full Body Hair Removal, Pubic Hair Removal, Manicure & Pedicure</div>
              <div className="home-package-price"><s>Rs. 18796 </s>11999 </div>
            </div>


            <div className="home-package-list">
              <div className="home-package-icon"><img src={icn7} alt="" /></div>
              <div className="home-package-heading">Men's Wedding Day Dapper Delight (Single Sitting)</div>
              <div className="home-package-text">Hair Spa, Facial O3+ With Detan O3+, Peel Of Mask & Sheet Mask, Full Body Polish (Steps -Cleanser, Scrub, Massage, Pack, Serum), Full Body Hair Removal, Pubic Hair Removal, Manicure & Pedicure, Haldi And Chandan Pack, Lighten The Dark Skin (Single Sitting)</div>
              <div className="home-package-price"><s>Rs. 21999 </s>17999 </div>
            </div>


            <div className="home-package-list">
              <div className="home-package-icon"><img src={icn2} alt="" /></div>
              <div className="home-package-heading">Men's Gleaming Groom's Journey (Double Sitting - Part I)</div>
              <div className="home-package-text">1st Sitting: Hair SPA, Facial O3+ With Detan O3+, Peel Of Mask & Sheet Mask, Full Body Scrub, Manicure & Pedicure, Haldi And Chandan Pack, Lighten The Dark Skin (1st Sitting),</div>
              <div className="home-package-heading">Men's Gleaming Groom's Journey (Double Sitting - Part II)</div>
              <div className="home-package-text">2nd Sitting: Hair Spa, Detan O3+, Facial O3+ Skin Whitening, Full Body Polish (Steps -Cleanser, Scrub, Massage, Pack, Serum), Full Body Hair Removal, Pubic Hair Removal, Manicure & Pedicure, Haldi And Chandan Pack, Lighten The Dark Skin (2nd Sitting)</div>
              <div className="home-package-price"><s>Rs. 35999 </s>29999 </div>
            </div>




          </div>
              <div className="home-package-button">
                {/* <span onClick={e=>{setBookingForm(true)}}>Book Appointment</span> */}
                </div>

        </div>
      </div>
        
        
    </div>
  )
}

export default WeddingGroomPackagesComponent