import React from 'react'
import AdminNav from '../../components/admin/AdminNav'
import AdminBookingComponent from '../../components/admin/AdminBookingComponent'

const AdminOrdersPage = () => {
  return (
    <div>
        <AdminNav/>
        <AdminBookingComponent/>
    </div>
  )
}

export default AdminOrdersPage