import React from 'react'

const BlogGlazmaComponent = () => {
  return (
    <div>
        <div className="">
          <div className="blog-page-col-2-glazma">
          <div className="blog-page-col-2-glazma-heading">Glazma Men's Salon</div>
          <div className="blog-page-col-2-glazma-text">We provide personalized full body wax, body scrub, body polishing, manicure, pedicure, facial, pubic hair shave your body hair from your head to your toes and all in between. We offers the best mens grooming and personal service of a fine cut and shave and make you look good and feel good.</div>

          </div>
          </div>
    </div>
  )
}

export default BlogGlazmaComponent