import React from "react";
import icon from "../../media/images/Full-Body-Hair-Removal-For-Male-Salon.png";

const FaqServiceComponent = () => {
  return (
    <div>
      <div className="service-faq">
        <div>
          <div className="service-faq-heading">FREQUENTLY ASKED QUESTIONS</div>
          <div className="about-section-3-icon">
            <img src={icon} alt="icon" />
          </div>
          <div className="service-faq-content">

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Do they provide the pre wedding grooming services at whole Delhi-NCR?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes, Glazma Beauty Salon provides the pre wedding grooming services for men at your preferred location in Noida, Greater Noida, Noida Extension, Indirapuram, Raj Nagar Extension, Ghaziabad, Faridabad, Gurgaon, and Delhi-NCR also</div>
            </div>
            

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How much time does pre wedding grooming sitting take to finished?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Pre-wedding grooming single sitting takes around 5-6 hours to finished sitting.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the measures beauty professionals take to maintain proper sanitation?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Measures that our certified beauty professionals take to maintain proper sanitation are:

• Professionals keep themselves fully-sanitized
• Beauty experts wear face masks, gloves & head caps
• Certified experts also keep your area sanitized.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Are your beauty experts well certified and trained?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes, our all-beauty experts are well-educated and well-trained. Also, they have an professional beauty center.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Can pre wedding grooming do wonders on my head to toe?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes, a pre wedding grooming package will get your charisma back and gift you a natural glow on your head to toe.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Should I expect to experience break out after men’s facial service?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>The short answer is no. The products we use are not irritating to the skin and should not cause breakout to surface. In fact, having a facial is recommended to speed the healing process and relieve the discomfort of current acne lesions.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What product line do you use in men’s facial services?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>The only line that we currently use is Image. We love this brand due to its dedication to staying as natural as possible while still delivering results that are only possible to achieve when using high levels of active ingredients. From the environmentally friendly packaging to the contents, Image is an incredible source for safe, effective skin care and uses only food grade preservatives, no synthetic dyes and only the best cold-pressed essential oils for fragrance.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What should i experience after men’s facial?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>You will leave your service feeling hydrated and relaxed and your skin will look healthy and glowing. If your appointment is later in the day we typically prepare your skin for night and no cleansing is necessary before bed. If your service is completed early in the day protection products will be applied and cleansing before bed will be recommended.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Should I have men’s facial before an important event?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>On average 3-6 weeks.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the ideal hair length for my wax?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Absolutely! Your skin will look healthy and glowing directly after your service.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Should I have a men’s facial if I have sensitive skin?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes. There are many factors involved in sensitive skin and sometimes a facial service can help determine through diagnosis what you can do at home to both offer relief for your current state as well as prevent your condition from worsening.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>May I have a body waxing service during men’s facial?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes. This is done routinely and is no problem to accommodate.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Why waxing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>There are many benefits to maintaining a regular hair removal cycle. Waxing regularly will cause the hair to grow back finer, thinner, and more sparse. We have three different hair growth cycles, please take into consideration that you may not be 100% smooth for your first wax. We recommend trusting us for at least 4 waxing services to experience the full benefits.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What’s your dipping policy?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Have no fear, at The Wax and Skin Lounge double dipping is a no-no. Waxing sticks are dipped into sterilized wax, applied to client once and then discarded.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What kind of wax do you use?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Our Aestheticians use a combination of both hard and soft waxes to create a personalized experience to better meet your hair removal needs. We use Hard Wax on the Bikini Area, Underarms, and Facial Waxing. Soft Wax is used for all other services. You can request hard wax for a service that’s usually done with soft wax for an additional fee.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How long does waxing last?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>On average 3-6 weeks.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the ideal hair length for my wax?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Hair must be at least 1/4 inch. We recommend 3-4 weeks growth.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Does Waxing hurt? Can I reduce the pain?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>We won’t lie to you, waxing can be uncomfortable. Everyone’s pain tolerance is different, but you can expect a short, stinging like sensation after strips are pulled. We recommend taking an ibuprofen about 30 minutes before your appointment. (Please consult your physician before taking any medication)

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How should I prepare for my wax appointment?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>We suggest 3-4 weeks hair growth for the body and 2 weeks growth for facial hair. For better results use a gentle exfoliant between waxing appointments.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What steps are taken to ensure proper hygiene?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>The Wax & Skin Lounge prides itself on the cleanliness and sanitation procedures we have in place. Our Aestheticians wear gloves. There is a “No Double Dipping Policy.” This ensures our Aestheticians are using a clean wax stick for each application of wax during your service, creating the ultimate level of sanitation. After using any tools/equipment, our staff is required to clean and sanitize to ensure no chance of cross-contamination. A fresh medical paper covering is used on the table for each guest. The table is also sanitized and disinfected after every guest.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is your staff licensed and certified?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes, all of our estheticians are licensed in the State of Illinois and have a strong background in skincare education. We require our staff to go through ongoing training and continually stay on top of the latest techniques and products.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How Long Will The Results Last?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Appointments are usually scheduled every 4 weeks. If this is your first waxing experience you can reasonably expect your results to last between 3 and 6 weeks with minimal growth in the days and weeks following your appointment, depending on how quickly your particular hair grows. Hair regrowth patterns are cyclical and you will notice an improvement in regard to the longevity of your results as you become regular with your waxing services.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Does Body Waxing Cause Hair to Re-Grow Thicker?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>No, This is a complete myth. Waxing, in fact, is know to actually stop hair growth all together in some cases. It is important to keep in mind though that waxing should be used as a temporary hair removal method as the slowing or stopping of hair growth takes many years of consistent waxing.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is Waxing Safe For Those With Sensitive Skin?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>If done properly, waxing can be a great benefit to people with sensitive skin. It is much more gentle on the skin than many other methods of hair removal, IE. shaving and at home depilatories. It is impossible to predict who will have a reaction to the wax or any product used. If you have sensitive skin it is very important to follow the instructions in the pre-waxing guide as well as the post-waxing guide.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is men’s Brazilian Wax Method?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body waxing has become a basic grooming ritual for many people, while for others, it remains an expression of one's sensuality. The men’s Brazilian waxing method of waxing is no exception and attracts all ages of women and men from all walks of life.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is a men’s Brazilian wax?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s Brazilian waxing is a style of waxing that removes all of the hair from the men’s bikini area to the backside. A typical men’s Brazilian wax leaves absolutely no hair in one's men’s bikini area, however, some choose to leave a small amount on the pubic bone. It is entirely up to the individual and we are able to accommodate nearly any request.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What should I expect during my Brazilian waxing service?
</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>When you book for your Brazilian waxing scheduled appointment you will be greeted and asked to fill out a short questionnaire, such is the case with any waxing service provided, you will then be shown to the treatment room and given instructions to disrobe from the waist down, to lie on the facial bed and you will be provided with a modesty towel to cover yourself. At that time, you will be given a moment of privacy to ready yourself. There is absolutely no awkward positioning and your wax will be provided in a professional manner by an experienced Esthetician.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What should I expect to experience after my Brazilian waxing service?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Aside from soft, smooth skin, you will likely notice the following: follicular edema, tenderness and redness. You may also experience itchiness as well as small bumps, which will subside on their own in roughly 3 days. This is all very normal and nothing to be alarmed over. Applying a small amount of over the counter cortisone cream will ease your symptoms. Following the pre and post-waxing guide will reduce the likelihood of experiencing irritation as well.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is a men’s Brazilian waxing more painful or bearable pain?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>There is always some pain but bearable associated with body waxing and the bikini area is quite sensitive, so yes, there will be some discomfort involved.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How much time should I allow?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes. A full Brazilian wax takes between 30 - 45 minutes to complete.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Who may receive a men’s Brazilin wax?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes. Any person above the age of 18 may be provided a men’s Brazilian waxing service. This is due to the intimate nature of this particular style of waxing. Persons under the age of 18 may be provided with a basic or extended men’s bikini wax but, due to the intimate nature of such services, will be required to provide written parental consent.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is a Brazilian wax?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A Brazilian wax is a type of hair removal treatment that involves the removal of hair from the pubic area and the buttocks. It is called a "Brazilian" wax because it originated in Brazil, where it was popularized by women who wanted to wear thong swimsuits and other clothing that required a bare genital area.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How long does a Brazilian wax last?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A Brazilian wax typically lasts for several weeks, depending on the individual's hair growth rate. Some people may experience regrowth after a week or two, while others may go longer between waxing sessions.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is a Brazilian wax painful?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A Brazilian wax can be a painful process, especially for people who are sensitive to waxing or who have never had it done before. It is common for people to experience some discomfort or pain during the waxing process, but the level of pain can vary depending on the individual's pain threshold and the sensitivity of their skin.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What should I do to prepare for a Brazilian wax?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>It is a good idea to take a shower and clean the area to be waxed before a Brazilian wax. It is also a good idea to avoid using lotions, creams, or oils on the skin before waxing, as these can interfere with the wax's ability to.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Why men’s body scrubbing is important?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>With time, dead skin cells accumulate on our body. Men’s body scrubbing is important in order to improve skin texture and color. The best way to maintain the look of the skin is to remove any build-up of old dry flaky skin.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the method of men’s body scrubbing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>There are two methods of body scrub procedure. One is a numbered stroke method, in which a certain number of brush strokes are designated for each finger, palm, back of hand, and arm. The alternative method is the timed scrub, and each scrub should last from three to five minutes, depending on facility protocol.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What should not do after men’s body scrubbing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Avoid using soaps for some time after body scrubbing as it may make the skin dry and parched. Consult a dermatologist in case you get some side effects or irritation after the body scrubbing.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How frequently we should do men’s body scrubbing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Even though it feels amazing and makes your body feel re-energized, it's important to keep a week's gap between two treatments to allow your body to absorb the goodness and to avoid extreme dryness. If you are prone to allergies and breakouts, be sure to do a patch test before applying the scrub all over your body.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How long does men’s body scrub last?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A standard treatment session of skin scrubbing continues for about 4 to 7 weeks, hence no sooner than this does the skin start to show improvement, in terms of reduction of marks and scars, or the removal of dead cells to brighten the skin, making it smoother and softer at the same time as well.

</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the purpose of body scrub?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes. Men’s body scrub exfoliates the skin of your body and is similar to a facial, but for your body instead of face. It leaves your skin glowing from head to toe. The men’s scrub therapist applies a scrub made of a natural exfoliant and essential oils to the guest's body.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the benefits of men’s body scrub?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body scrubs remove dead skin cells, and so provide several benefits:

1. They allow your skin to absorb moisturizer better. By doing dead skin cell build-up, any moisturizer applied afterward will soak into the skin more thoroughly.
2. They unclog pores and prevent ingrown hairs.
3. They leave your skin smoother and more even.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is men’s body scrub good for dry skin?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body scrubbing helps unclog your pores and helps other products absorb into your skin better. This helps deeply hydrate the skin from within, moisturize it and reduce surface dryness.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is included in men’s body polishing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body polishing is a type of full body exfoliation that removes dead skin cells, promotes cell regeneration, and moisturizes the skin. It is typically found on spa menus as a way to prepare men’s skin for other treatments, like wraps. Think of it as a facial for the men’s body.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How frequently we should do men’s body polishing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Even though it feels amazing and makes men’s body feel re-energized, it's important to keep a week's gap between two treatments to allow men’s body to absorb the goodness and to avoid extreme dryness.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How many types of men’s body polish are there?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Body polish come in three different types, i.e., sugar body polish, salt body polish, and herbal body polish. You can choose the one that fits your requirements</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the purpose of men’s body polish?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body polish exfoliates the skin of men’s body and is similar to a facial, but for men’s body instead of face. It leaves men’s skin glowing from head to toe. The body polish therapist applies a polish made of a natural exfoliant and essential oils to the men’s body.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How many steps are in body polish?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>It involves around 7 steps that focus on providing hydration to the skin. The procedure is done using various cosmetic products that play different roles in each step. Men’s body polishing is a type of full-body exfoliation that removes dead skin cells, promotes cell regeneration, and moisturizes the skin.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is men’s body polishing good for dry skin?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body polishing helps unclog your pores and helps other products absorb into your skin better. This helps deeply hydrate the skin from within, moisturise it and reduce surface dryness.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the difference between men’s body scrub and men’s body polishing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body scrub is meant to cleanse the skin and exfoliate at the same time. Typically, they get sudsy and rinse away clean.
Men’s body polish does not cleanse, but mainly moisturizes and exfoliates the skin. This means men’s skin/feet should be left with a super moisturized skin.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the men’s body polishing process?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body polishing is the process of creating a smooth and shiny surface by rubbing it or by applying a chemical treatment, leaving a clean surface with a significant specular reflection (still limited by the index of refraction of the material according to the Fresnel equations).</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the purpose of men’s body polish?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s body polishing exfoliates the skin of men’s body and is similar to a facial, but for your body instead of face. It leaves men’s skin glowing from head to toe. Men’s therapist applies a polish made of a natural exfoliant and essential oils to the guest's body.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What should not do after men’s body polishing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Avoid using soaps for some time after men’s body polishing as it may make the skin dry and parched. Consult a dermatologist in case you get some side effects or irritation after the men’s body polishing.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the effects of men’s body polishing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Me’s body polishing has numerous benefits for men’s skin, including: exfoliating men’s skin to remove dead skin cells. unclogging pores to prepare for men’s body treatment. promoting cell regeneration to encourage healthy skin.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How long does men’s body polish last?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A standard treatment session of skin polishing continues for about 6 to 12 weeks, hence no sooner than this does the skin start to show improvement, in terms of reduction of marks and scars, or the removal of dead cells to brighten the skin, making it smoother and softer at the same time as well.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the 5 stapes of men’s body polishing?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Massage oil, Cleanser, Massage cream, Scrub, Oxy pack</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is men’s manicure and pedicure?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s manicure refers to the curation and care of a client's hands and a pedicure refers to the curation and care of a client's feet. This includes skincare, nail care, and artificial nail enhancements that can be customized to a variety of preferences.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the main purpose of men’s manicure and pedicure?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s manicure and pedicure provides a deep clean for your nails, as well as ensuring that they looking great. This is an important part of positive nail health, as both our hands and feet go through a lot on a daily basis.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the beneficial effects of men’s manicure and pedicure?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Benefits of Regular Men’s Manicure and Pedicure Service:

1. Prevents infections.
2. Promotes healthy blood circulation.
3. Boosted nail health.
4. Reduction in back pain.
5. It can make your hands and feet look younger.
6. Improved mental health.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is the purpose of men’s nail and feet care?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Men’s nail care, the maintenance of the fingernails and toenails, is important for health as well as cosmetic reasons. Good nail care can prevent fungus infections of the nail, painful ingrown fingernails and toenails, and infections of the skin in the hands and feet.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Why is it important to know the benefits of men’s manicure and pedicure?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>It can make your hands and feet look younger. Men’s manicures and pedicures improve blood flow, which is important in reducing cellulite, tightening your skin, and strengthening your muscles — all of which can improve the visible appearance of your hands and feet.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Why post care is needed after men’s manicure and pedicure ?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Moisturize. Keeping the hands, feet, and nails moisturized is essential to their overall health. When moisture is not present, it leads to other problems like dry cuticles, cracking hands, brittle nails, etc. Moisturizing the service areas keeps the skin supple and easier to work with on subsequent visits.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the contraindications to men’s manicure and pedicure?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Examples of contra-indications that may prevent treatment: Fungal nail/skin infections, bacterial nail/skin infections, viral nail/skin infections, severe eczema, psoriasis or dermatitis, open wounds/cuts/abrasions local to treatment area, nail plate separation (know when to seek medical advice).</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the safety precautions in men’s manicure and pedicure?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>What are some preventive measures for a nail salon? Wash hands regularly especially before and after working with clients to prevent infection. Moisturize your hands regularly. Treat all body substances such as blood as potentially infectious – always wear gloves.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How long is a men’s manicure and pedicure?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>They are restorative and relaxing and are essential for the health of men’s nails. Furthermore, the processes are relatively quick—the answer to “How long does a manicure and pedicure take?” is less than an hour and usually between 30 and 60 minutes.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How do I book a massage at home?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>You have to book an appointment by visiting our website www.glazma.com or you can call us in +91 9658-153-153.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is it more expensive to get a massage at home?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>You don't have to worry about money at Glazma Men's Salon because Glazma will send you the best expert for your services, it can also be more convenient and comfortable to receive a massage at home because you don't have to travel to a massage studio or spa.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What do I need to prepare for a massage at home?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>There are a few things you can do to prepare for a massage at home:

1. Make sure you have a comfortable, quiet place for the massage to take place. This could be a bed, couch, or massage table.
2. Ensure that the room is warm enough, as you will likely be undressing for the massage.
3. If you are using a massage table, make sure it is clean and set up in a stable location.
4. Communicate with the therapist about any specific areas you would like them to focus on, or any injuries or sensitivities they should be aware of.
</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How long does a massage at home take?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>The length of a massage at home will depend on the type of massage you are getting and how long you have booked the session for. Massage sessions can range from 30 minutes to several hours, and the therapist will typically discuss the length of the session with you when you book the appointment.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Will the therapist bring their own massage table?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>No, in Glazma, the therapist is not able to bring the table with them, but they provide all products except the table.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Can I request a male or female therapist?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>No, Glazma - Men's Salon provides only male therapist to provide their services to you.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Are there any health conditions or injuries that would prevent me from getting a massage at home?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Yes, if you have any health conditions or injuries then this is your responsibility to inform in the CRM team of Glazma- Men’s Salon.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How should I tip the therapist for a massage at home?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>Tipping is not required for massage services, but it is a common practice to show appreciation for a job well done. If you feel the therapist has provided excellent service and you would like to leave a tip, you can do so in cash or add the tip to your payment if you are paying with a UPI.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What is a hair spa treatment?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A hair spa treatment is a type of hair treatment that is designed to nourish and revitalize the hair and scalp. It can include a variety of services, such as a scalp massage, hair mask, and other treatments designed to improve the health and appearance of the hair.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What are the benefits of a hair spa treatment?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A hair spa treatment can help to nourish and moisturize the hair and scalp, improve circulation, repair damaged hair, and provide relaxation and rejuvenation. It can also improve the overall appearance of the hair, leaving it looking and feeling healthy and refreshed.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>How often should I get a hair spa treatment?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>The frequency of hair spa treatments can vary depending on the individual's hair type and needs. Some people may benefit from getting a hair spa treatment every few weeks, while others may be able to go longer between treatments. It is a good idea to consult with a stylist or hair care professional to determine the best schedule for your individual needs.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>Is a hair spa treatment painful?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>A hair spa treatment is generally not painful, but some people may experience slight discomfort during a scalp massage or if they have sensitive skin.</div>
            </div>

            <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span>What should I do to prepare for a hair spa treatment?</div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span>It is a good idea to wash your hair before a hair spa treatment to remove any excess dirt or oil. It is also a good idea to avoid using styling products or wearing tight hairstyles that may interfere with the treatment. It is best to arrive at the salon or spa with clean, dry hair.</div>
            </div>

            {/* <div className="service-faq-content-list">
              <div className="service-faq-content-list-ques"><span>Ques.&nbsp;</span></div>
              <div className="service-faq-content-list-ans"><span>Ans.&nbsp;</span></div>
            </div> */}

          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqServiceComponent;
