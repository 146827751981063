import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import BlogBreadCrumbComponent from "../../components/user/BlogBreadCrumbComponent";
import img1 from "../../media/images/blog-img/glazma-mens-hair-colouring.jpg";

import RecentBlogComponent from "../../components/blog/RecentBlogComponent";
import BlogGlazmaComponent from "../../components/blog/BlogGlazmaComponent";
import { Helmet } from "react-helmet";

const  HairColourAtHome = () => {
  return (
    <div>


    <Helmet>
            <title>Blog - Glazma Men's Saloon - HAIR COLOUR AT HOME</title>
            <meta
              name="description"
              content="
              If you’ve stepped into your age near your thirties, chances are those white hairs are peeking out of your head hairs. Hair colouring is certainly one of the great commonly
             "
            />
          </Helmet>
      
      
      <Navbar />
      <BlogBreadCrumbComponent name="Hair Colour At Home" />

      <div className="blog-page">
        <div>
          <div className="single-blog">

            <h1 className="single-blog-heading">HAIR COLOUR AT HOME</h1>
            <div className="single-blog-text">If you’ve stepped into your age near your thirties, chances are those white hairs are peeking out of your head hairs. Hair colouring is certainly one of the great commonly practiced grooming steps taken by males.

<br />
But there are a lot of issues you must think of– should you go to the men's salon or do it yourself at your home? Will it be time-consuming? How much efforts will you have to give? Will it be costly?

<br />
Well, don’t worry, Glazma is here to give you a broad overview of hair colouring at home and the salon, and also provide you with the best possible solution for the hair colour at your doorstep!

<br />
Moreover, you have the comfort of your own preferred location with your preferred time and date also and can choose to spend time in any way you want while the hair colour sets.
<br />
Our hair colour at home services is also more affordable than getting hair coloured in salons. Top-class reliable Salon for male services at your convenience location in Noida, Greater Noida, Ghaziabad, Faridabad, Gurgaon, and Delhi-NCR also.</div>

            <div className="single-blog-img">
              <img title="blog-img"  src={img1} alt="blog-img" />
            </div>

       

            <div className="single-blog-heading">BENEFITS OF HAIR COLOR</div>
            <div className="single-blog-heading-1">Convenience</div>
            <div className="single-blog-text-1">You don't have to leave your home to get your hair colored.</div>


            <div className="single-blog-heading-1">Comfort</div>
            <div className="single-blog-text-1">You can relax in your own space while getting your hair colored.</div>


            <div className="single-blog-heading-1">Flexibility</div>
            <div className="single-blog-text-1">You can schedule the appointment at a time that is convenient for you.

</div>


            <div className="single-blog-heading-1">Cost-Effectiveness</div>
            <div className="single-blog-text-1">Getting your hair colored at home may be more cost-effective than going to a salon.</div>


            <div className="single-blog-heading-1">Privacy</div>
            <div className="single-blog-text-1">You may feel more comfortable getting your hair colored in the privacy of your own home.</div>


            <div className="single-blog-heading">DON'T THINK MORE TRY US OUT</div>
            <div className="single-blog-text">Our stylist hair color expert come at your doorstep to give you the perfect style.</div>


            <div className="single-blog-heading">REASONS TO GET A HAIR COLOR</div>
            <div className="single-blog-heading-1">	
Customization</div>
            <div className="single-blog-text-1">Men may want to work with the stylist to achieve a specific look that is tailored to their preferences.</div>


            <div className="single-blog-heading-1">Quality</div>
            <div className="single-blog-text-1">Men may still want to receive high-quality hair coloring services, even if they are at home.</div>


            <div className="single-blog-heading-1">Safety</div>
            <div className="single-blog-text-1">Men may choose to get hair color services at home to avoid the risk of contracting or spreading illness.</div>


            <div className="single-blog-heading-1">Convenience</div>
            <div className="single-blog-text-1">Men may choose to get hair color services at home for the convenience of not having to leave their home.</div>


            <div className="single-blog-heading-1">Privacy</div>
            <div className="single-blog-text-1">Men may prefer to get their hair colored in private rather than at a salon.</div>


            <div className="single-blog-heading">GET SALON-QUALITY COLOR AT HOME</div>
            <div className="single-blog-text">Achieve your desired hair color look from the comfort of your own home with our expert stylists.</div>

          </div>

          <div className="blog-page-col-2">
            <BlogGlazmaComponent />
            <RecentBlogComponent />
          </div>
        </div>
      </div>

      <LocationComponent />
      <FooterComponent />
    </div>
  )
}

export default HairColourAtHome