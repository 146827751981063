import React from 'react'
import Navbar from '../../components/user/Navbar'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import WeddingGroomPackagesComponent from '../../components/user/WeddingGroomPackagesComponent'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'

const WeddingGroomPackagesPage = () => {
  return (
    <div>
        <Navbar/>
        <BreadCrumbComponent name="Wedding Groom Packages"/>
<WeddingGroomPackagesComponent/>
<LocationComponent/>
<FooterComponent/>
    </div>
  )
}

export default WeddingGroomPackagesPage