import React from "react";
import img from "../../media/images/Full-Body-Wax-Price.png";
import { Link } from "react-router-dom";

import img1 from "../../media/images/Pubic-Hair-Removal-Salon-In-Delhi.jpg";
import img2 from "../../media/images/full-body-scrubMale-Pubic-Hair-Removal-Salon-In-Delhi.jpg";
import img3 from "../../media/images/Male-Waxing-Near-Me.jpg";
import img4 from "../../media/images/Body-Hair-Removal-For-Men.jpg";


const DayToDayComponent = () => {
  return (
    <div>
      <div className="day-2-day">
        <div>
          <h2 className="day-2-day-heading">
            DAY TO DAY CARE – MEN'S BODY WAX SERVICES
          </h2>
          <div className="day-2-day-icon">
            <img title="body wax expert near me" src={img} alt="icon" />
          </div>
          <div className="day-2-day-content">

            <div className="day-2-day-content-list">
              <div className="day-2-day-content-list-heading">FULL BODY WAX AT HOME</div>
              <div className="day-2-day-content-list-image"><img title="Intimate area expert near me" src={img1} alt="img" /></div>
              <div className="day-2-day-content-list-text">
              Body waxing has never been so easy, we provide full body hair waxing like chest hair wax, back hair wax, hand and legs hair wax, pubic hair wax, full body hair trimming....
                <Link title="Best Way To Remove Pubic Hair" target="blank" to="/full-body-wax"> read more...</Link>
              </div>
            </div>
            

            <div className="day-2-day-content-list">
              <div className="day-2-day-content-list-heading">FULL BODY SCRUB AT HOME</div>
              <div className="day-2-day-content-list-image"><img title="Mens waxing expert near me" src={img2} alt="img" /></div>
              <div className="day-2-day-content-list-text">
              We provide full body scrub from an experienced salon artist at your home while watching TV and enjoying a quality time with your families...
                <Link title="Best Bikini Hair Removal" target="blank" to="/full-body-scrub"> read more...</Link>
              </div>
            </div>
            

            <div className="day-2-day-content-list">
              <div className="day-2-day-content-list-heading">FULL BODY POLISH AT HOME</div>
              <div className="day-2-day-content-list-image"><img title="Beauty treatment at home" src={img3} alt="img" /></div>
              <div className="day-2-day-content-list-text">
              Our experts recommend the best type of full body polish that will give your body natural smartlook we have a variety of latest body polishing products to pick from....
                <Link title="Men's Waxing Near Me" target="blank" to="/full-body-polishing"> read more...</Link>
              </div>
            </div>
            

            <div className="day-2-day-content-list">
              <div className="day-2-day-content-list-heading">FACIAL & DETAN AT HOME</div>
              <div className="day-2-day-content-list-image"><img title="Parlour home services" src={img4} alt="img" /></div>
              <div className="day-2-day-content-list-text">
              Get the facial and detan that'll suit you best at the comfort of your home, our salon experts will give your face and full body natural smartlook at your home....
                <Link title="Waxing Salons Near Me" target="blank" to="/men-facial"> read more...</Link>
              </div>
            </div>
            
          </div>
          <div className="day-2-day-button">
            <Link title="Manzilian Wax Near Me" target="blank" to="/blog">Read more Blog and Article</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayToDayComponent;
