import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyComponent = () => {
  return (
    <div>
      <div className="privacy-policy">
        <div>

          <div className="privacy-policy-col">
            <div className="privacy-policy-col-text">
            AT 
            <Link to="/"> WWW.GLAZMA.COM</Link>
            , WE RECOGNIZE THAT PRIVACY OF YOUR PERSONAL INFORMATION IS IMPORTANT. HERE IS INFORMATION ON WHAT TYPES OF PERSONAL INFORMATION WE RECEIVE AND COLLECT WHEN YOU VISIT 
            <Link to="/"> WWW.GLAZMA.COM </Link>
             AND HOW WE SAFEGUARD YOUR INFORMATION. WE NEVER SELL YOUR PERSONAL INFORMATION TO THIRD PARTIES.
            </div>
          </div>
          

          <div className="privacy-policy-col">
            <div className="privacy-policy-col-heading">COOKIES</div>
            <div className="privacy-policy-col-text">
            WE DO USE COOKIES TO STORE INFORMATION, SUCH AS YOUR PERSONAL PREFERENCES WHEN YOU VISIT OUR SITE. THIS COULD INCLUDE ONLY SHOWING YOU A POPUP ONCE IN YOUR VISIT, OR THE ABILITY TO LOGIN TO SOME OF OUR FEATURES.
            <br />
GLAZMA.COM, UNDERSTANDS THAT YOUR PRIVACY IS IMPORTANT TO YOU AND WE ARE COMMITTED FOR BEING TRANSPARENT ABOUT THE TECHNOLOGIES WE USE.
            <br />
BY CONTINUING TO BROWSER OR USE OUR SITES, YOU AGREE THAT WE CAN STORE AND ACCESS COOKIES AND OTHER TRACKING TECHNOLOGIES AS DESCRIBED IN THIS POLICY.
            </div>
          </div>


          <div className="privacy-policy-col">
            <div className="privacy-policy-col-heading">PERSONAL IDENTIFICATION INFORMATION</div>
            <div className="privacy-policy-col-text">
            WHEN USERS VISIT OUR SITE, SUBSCRIBE TO THE NEWSLETTER, FILL OUT A FORM, AND IN CONNECTION WITH OTHER ACTIVITIES, SERVICES, FEATURES OR RESOURCES WE MAKE AVAILABLE ON OUR SITE. USERS MAY BE ASKED FOR, AS APPROPRIATE, NAME, EMAIL ADDRESS.
            <br />
IN THE COURSE OF REGISTERING FOR AND AVAILING VARIOUS SERVICES WE PROVIDE FROM TIME TO TIME THROUGH OUR WEBSITE HTTP://WWW.GLAZMA.COM YOU MAY BE REQUIRED TO GIVE YOUR NAME, ADDRESS, EMAIL ADDRESS, PHONE NUMBER.
            <br />
THE PERSONAL INFORMATION IS USED FOR THREE GENERAL PURPOSES: TO CUSTOMIZE THE CONTENT YOU SEE, TO FULFILL YOUR REQUESTS FOR CERTAIN SERVICES, AND TO CONTACT YOU ABOUT OUR SERVICES. UNLESS OTHERWISE STATED EXPLICITLY, THIS POLICY APPLIES TO PERSONAL INFORMATION AS DISCLOSED ON ANY OF THE MEDIA.
            </div>
          </div>


          <div className="privacy-policy-col">
            <div className="privacy-policy-col-heading">SECURITY</div>
            <div className="privacy-policy-col-text">
            PERSONAL INFORMATION WILL BE KEPT CONFIDENTIAL AND WE DO NOT DISCLOSE THE INFORMATION EXCEPT THAT IN CASE YOU HAVE SPECIFICALLY MADE AN ENQUIRY . FURTHER, THE VENDORS / ADVERTISERS WHO ARE LISTED WITH US, MAY CALL YOU, BASED ON THE QUERY OR ENQUIRY THAT YOU MAKE WITH US, ENQUIRING ABOUT ANY PRODUCT / SERVICE THEY MIGHT OFFER
            <br />
WE WILL SHARE PERSONAL INFORMATION ONLY UNDER ONE OR MORE OF THE FOLLOWING CIRCUMSTANCES: – IF WE HAVE YOUR CONSENT OR DEEMED CONSENT TO DO SO – IF WE ARE COMPELLED BY LAW (INCLUDING COURT ORDERS) TO DO SO.
            <br />
IN FURTHERANCE OF THE CONFIDENTIALITY WITH WHICH WE TREAT PERSONAL INFORMATION WE HAVE PUT IN PLACE APPROPRIATE PHYSICAL, ELECTRONIC, AND MANAGERIAL PROCEDURES TO SAFEGUARD AND SECURE THE INFORMATION WE COLLECT ONLINE.
            <br />
WE GIVE YOU THE ABILITY TO EDIT YOUR ACCOUNT INFORMATION AND PREFERENCES AT ANY TIME, INCLUDING WHETHER YOU WANT US TO CONTACT YOU REGARDING ANY SERVICES. TO PROTECT YOUR PRIVACY AND SECURITY, WE WILL ALSO TAKE REASONABLE STEPS TO VERIFY YOUR IDENTITY BEFORE GRANTING ACCESS OR MAKING CORRECTIONS.
            <br />
WE TREAT DATA AS AN ASSET THAT MUST BE PROTECTED AGAINST LOSS AND UNAUTHORIZED ACCESS. WE EMPLOY MANY DIFFERENT SECURITY TECHNIQUES TO PROTECT SUCH DATA FROM UNAUTHORIZED ACCESS BY MEMBERS INSIDE AND OUTSIDE THE COMPANY. HOWEVER, “PERFECT SECURITY” DOES NOT EXIST ON THE INTERNET, OR ANYWHERE ELSE IN THE WORLD! YOU THEREFORE AGREE THAT ANY SECURITY BREACHES BEYOND THE CONTROL OF OUR STANDARD SECURITY PROCEDURES ARE AT YOUR SOLE RISK AND DISCRETION.
            </div>
          </div>


          <div className="privacy-policy-col">
            <div className="privacy-policy-col-heading">EMBEDDED CONTENT FROM OTHER WEBSITES</div>
            <div className="privacy-policy-col-text">
            SOMETIMES OUR ARTICLES MAY INCLUDE EMBEDDED CONTENT (E.G. VIDEOS, IMAGES, ARTICLES, ETC.).
            <br />
EMBEDDED CONTENT FROM OTHER WEBSITES BEHAVES IN THE EXACT SAME WAY AS IF THE VISITOR HAS VISITED THE OTHER WEBSITE.
            </div>
          </div>


          <div className="privacy-policy-col">
            <div className="privacy-policy-col-heading">ANALYTICS</div>
            <div className="privacy-policy-col-text">
            ONLY FOR TRACKING AND WEB PERFORMANCE WE USE GOOGLE ANALYTICS AND WEBMASTER.
            </div>
          </div>


          <div className="privacy-policy-col">
            <div className="privacy-policy-col-heading">CONTACT US</div>
            <div className="privacy-policy-col-text">
            IF YOU HAVE ANY FURTHER QUERIES REGARDING THE PRIVACY POLICY, FEEL FREE TO CONTACT US AT INFO@GLAZMA.COM
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
