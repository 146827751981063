
import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import BlogBreadCrumbComponent from "../../components/user/BlogBreadCrumbComponent";
import img1 from "../../media/images/blog-img/Glazma-scrub.jpg";

import RecentBlogComponent from "../../components/blog/RecentBlogComponent";
import BlogGlazmaComponent from "../../components/blog/BlogGlazmaComponent";
import { Helmet } from "react-helmet";

const  FullBodyScrub = () => {
  return (
    <div>


    <Helmet>
            <title>Blog - Glazma Men's Saloon - FULL BODY SCRUB</title>
            <meta
              name="description"
              content="
              A body scrub may be a physical exfoliant that is usually either salt or sugar-based, wont to remove dead skin cells from your skin. 
              
             "
            />
          </Helmet>
      
      
      <Navbar />
      <BlogBreadCrumbComponent name="Full Body Scrub" />

      <div className="blog-page">
        <div>
          <div className="single-blog">

            <h1 className="single-blog-heading">FULL BODY SCRUB</h1>
            <div className="single-blog-text">A body scrub may be a physical exfoliant that is usually either salt or sugar-based, wont to remove dead skin cells from your skin. The abrasive ingredients (sugar, salt) are worked into the skin and work as a natural exfoliant, clearing the skin of older skin cells so your skin feels soft supple and nourished. Body scrub works by stimulating somatic cell turnover for smoother, brighter skin, and may help restore’s skins natural moisture, prevent future acne breakouts, and cause you to feel great!

<br />
There are many body scrubs out there, for whatever your skin type could also be , so you'll find one that works for you to remove the dead skin cells without doing any harm to your skin. Body scrubs help restore your skin back to its natural, soft and supple state and are an excellent addition to any skincare routine.

<br />
Body scrubs are around for a while, but quite variety has emerged over the years, including those made from coffee, salt, and sugar. you'll even have a few of them hanging out in your bathroom cabinet right now.

<br />
Whether or not you’ve tried one before, you'll wonder what benefits, if any, are related to body scrubs.

<br />
Aside from making your skin feel super-smooth, body scrubs have quite few other benefits.

<br />
Body scrubs are an honest thing to have in your skincare wardrobe, especially within the winter months when the skin can get very dry.</div>

            <div className="single-blog-img">
              <img title="blog-img"  src={img1} alt="blog-img" />
            </div>

            <div className="single-blog-heading">BENEFITS OF FULL BODY SCRUB</div>
            <div className="single-blog-heading-1">Remove Dead Skin Cells</div>
            <div className="single-blog-text-1">Skin continuously sheds dead skin cells and regenerates new ones, however, it could sometimes use a hand in the process. employing a body scrub to clear away dead skin build-up can help to kick start most of the benefits listed below.</div>


            <div className="single-blog-heading-1">	
Encourage Skin Rejuvenation</div>
            <div className="single-blog-text-1">
            By exfoliating and removing any dead skin cells, scrubs encourage the healthy rejuvenation of skin, making it look fresh and velvety soft.
            </div>


            <div className="single-blog-heading-1">
            Draw out Ingrown Hairs
            </div>
            <div className="single-blog-text-1">
            Ingrown hairs are a daily nuisance for anyone that shaves, waxes or plucks and may sometimes feel uncomfortable or get infected. employing a scrub helps to both prevent, also as draw out, any ingrown hairs.
            </div>


            <div className="single-blog-heading-1">Increases Self-Confidence</div>
            <div className="single-blog-text-1">
            When your skin looks nearly as good as it feels you’ll feel more confident about showing it off. Whether heading to the beach or flaunting a replacement backless dress, you’ll certainly feel safer without those acne spots on your back or that dry skin on your knees.
            </div>


            <div className="single-blog-heading-1">Relax your Mind</div>
            <div className="single-blog-text-1">
            The process of body care is thoroughly soothing. The calming massaging experience of applying an exfoliating body scrub helps to unwind the mind also as the body, in anticipation for the day or cosy night ahead.
            </div>


            <div className="single-blog-heading">DON'T THINK MORE TRY US OUT</div>
            <div className="single-blog-heading-1">Improved Skin Health</div>
            <div className="single-blog-text-1">A full body scrub can help to improve the health of the skin by removing dead skin cells and unclogging pores, which can help to prevent breakouts and improve the overall appearance of the skin.</div>


            <div className="single-blog-heading-1">Relaxation</div>
            <div className="single-blog-text-1">A full body scrub can be a relaxing and rejuvenating experience, especially when done in a spa setting.</div>


            <div className="single-blog-heading-1">Smooth Skin</div>
            <div className="single-blog-text-1">A full body scrub can help to smooth and soften the skin, leaving it feeling silky and refreshed.</div>


            <div className="single-blog-heading-1">Improved Circulation
</div>
            <div className="single-blog-text-1">The massage-like action of a full body scrub can help to improve circulation, giving the skin a healthier, more vibrant appearance.</div>


            <div className="single-blog-heading-1">	
Stress Relief</div>
            <div className="single-blog-text-1">The relaxation and pampering aspects of a full body scrub can help to reduce stress and promote a sense of well-being.</div>


            <div className="single-blog-heading">TRANSFORM YOUR FULL BODY SKIN</div>
            <div className="single-blog-text">Escape the stresses of daily life and indulge in the ultimate pampering with our full body scrub.</div>


            
          </div>

          <div className="blog-page-col-2">
            <BlogGlazmaComponent />
            <RecentBlogComponent />
          </div>
        </div>
      </div>

      <LocationComponent />
      <FooterComponent />
    </div>
  )
}

export default  FullBodyScrub