import React from 'react'
import AdminInquiryComponent from '../../components/admin/AdminInquiryComponent'

const AdminInquiryPage = () => {
  return (
    <div>
        <AdminInquiryComponent />
    </div>
  )
}

export default AdminInquiryPage