import React from 'react'
import icon from "../../media/images/Full-Body-Hair-Removal-For-Male-Salon.png";
import img1 from "../../media/images/gallery/gallery-images (1).jpg";
import img2 from "../../media/images/gallery/gallery-images (2).jpg";
import img3 from "../../media/images/gallery/gallery-images (3).jpg";
import img4 from "../../media/images/gallery/gallery-images (4).jpg";
import img5 from "../../media/images/gallery/gallery-images (5).jpg";
import img6 from "../../media/images/gallery/gallery-images (6).jpg";
import img7 from "../../media/images/gallery/gallery-images (7).jpg";
import img8 from "../../media/images/gallery/gallery-images (8).jpg";
import img9 from "../../media/images/gallery/gallery-images (9).jpg";
import img10 from "../../media/images/gallery/gallery-images (10).jpg";
import img11 from "../../media/images/gallery/gallery-images (11).jpg";
import img12 from "../../media/images/gallery/gallery-images (12).jpg";
import img13 from "../../media/images/gallery/gallery-images (13).jpg";
import img14 from "../../media/images/gallery/gallery-images (14).jpg";
import img15 from "../../media/images/gallery/gallery-images (15).jpg";
import img16 from "../../media/images/gallery/gallery-images (16).jpg";


const GalleryPageComponent = () => {
  return (
    <div>
        <div className="gallery-page">
            <div>
        <h1 className="gallery-page-heading">DAY TO DAY CARE & CORPORATE SPECIAL CARE</h1>
        <div className="gallery-page-icon"><img title='Male Waxing Near Me' src={icon} alt="Male Hair Removal Near Me" /></div>
        <div className="gallery-page-images">
            <img title='gallery image' src={img1} alt="gallery-img" />
            <img title='gallery image' src={img2} alt="gallery-img" />
            <img title='gallery image' src={img3} alt="gallery-img" />
            <img title='gallery image' src={img4} alt="gallery-img" />
            <img title='gallery image' src={img5} alt="gallery-img" />
            <img title='gallery image' src={img6} alt="gallery-img" />
            <img title='gallery image' src={img7} alt="gallery-img" />
            <img title='gallery image' src={img8} alt="gallery-img" />
            <img title='gallery image' src={img9} alt="gallery-img" />
            <img title='gallery image' src={img10} alt="gallery-img" />
            <img title='gallery image' src={img11} alt="gallery-img" />
            <img title='gallery image' src={img12} alt="gallery-img" />
            <img title='gallery image' src={img13} alt="gallery-img" />
            <img title='gallery image' src={img14} alt="gallery-img" />
            <img title='gallery image' src={img15} alt="gallery-img" />
            <img title='gallery image' src={img16} alt="gallery-img" />
        </div>

            </div>
        </div>
    </div>
  )
}

export default GalleryPageComponent