import React from "react";
import { Link } from "react-router-dom";

const ContactButtons = () => {
  return (
    <div>
      <div className="contact-fix-buttons">
        <Link title="Cheap Waxing Near Me" className="contact-fix-button-call"  target='"_blank' to="tel:+919658153153"><span><i className="fa-solid fa-phone-volume"></i></span></Link>
        <Link title="Best Waxing Near Me" className="contact-fix-button-wa" target='"_blank' to="https://wa.me/+919658153153?text=Hello ! I'm Interested in Doorstep Men grooming Service. Please contact me."><i className="fa-brands fa-whatsapp"></i></Link>
      </div>
    </div>
  );
};

export default ContactButtons;
